.ChangePassword,
.ForgotPassword {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(6px);
    position: absolute;
    z-index: -1;
  }

  .slider {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 382px;
    height: 405px;
    background: white;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0px 0px 25px 0px rgba(81, 111, 144, 0.3);

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 68px;
      background: linear-gradient(90deg, #1a73e8, #66c7cd);
      color: #fff;

      p {
        margin-left: 31px;
        font-weight: 700;
        color: #fff;
        font-size: 16px;
      }

      svg {
        width: 30px;
        height: 30px;
        margin-right: 16px;
        cursor: pointer;
      }
    }
  }

  .container {
    padding: 27px 26px;

    p {
      font-size: 16px;
      margin: 0;
      margin-bottom: 23px;
    }

    .buttons {
      display: flex;
      width: 100%;
      padding-top: 20px;
      padding-bottom: 32px;

      button {
        background: #1a73e8;
        height: 42px;
        min-width: 178px;
        border-radius: 8px;
        color: #fff;
        font-size: 14px;
        border: 1px solid transparent;
        box-sizing: border-box;

        &:nth-of-type(2) {
          min-width: 128px;
          margin-left: 9px;
          color: #516f90;
          background: #f5f8fa;
          border: 1px solid #cbd6e2;
        }
      }
    }
  }
}

.ForgotPassword {
  .slider {
    width: 534px;
    height: 260px;

    .container {
      padding-top: 17px;
      padding-bottom: 17px;
    }
  }
}

@media (max-width: 1024px) {
  .ChangePassword,
  .ForgotPassword {
    .slider {
    }
  }
}
