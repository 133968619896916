.NotFoundPage {
  width: 100%;
  height: 100vh;
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f7;

  a {
    margin: auto;
    width: 150px;
    height: 50px;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
    color: black;
    &:hover {
      border: none;
      background-color: aquamarine;
      -webkit-transition: background-color 1000ms linear;
      -ms-transition: background-color 1000ms linear;
      transition: background-color 1000ms linear;
    }
  }
}
