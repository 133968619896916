.Ticket {
  max-width: 510px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0px 0px 12px 0px rgba(81, 111, 144, 0.12);
  margin-top: 44px;
  margin-bottom: 25px;
  padding: 25px 29px 15px 29px;
  box-sizing: border-box;

  .total {
    font-size: 16px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;

    p {
      color: #253342;
    }
  }

  hr {
    height: 1px;
    background-color: #efefef;
    border: none;
    margin: 0;
  }

  .method {
    p {
      color: #33475b;
      font-size: 15px;
    }
  }
}

@media (max-width: 1024px) {
  .Ticket {
    margin-left: auto;
    margin-right: auto;
  }
}
