.Place {
  padding-top: 107px;
  height: 100%;

  .mobile-navigation {
    display: none;
    &.not-scrolled,
    &.scrolled {
      opacity: 0;
      pointer-events: none;
    }
  }

  .main {
    display: flex;
    justify-content: space-between;
    max-width: 1440px;
    padding: 0 80px;
    margin: 0 auto;
    height: 100%;
    box-sizing: border-box;
    .go-back {
      display: none;
    }

    .navigation-favourite {
      position: sticky;
      background: white;
      top: 70px;
      display: flex;
      align-items: center;
      z-index: 10;
      margin-bottom: 20px;

      button {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        min-width: 98px;
        height: 35px;
        border-radius: 8px;
        border: 1px solid #253342;
        margin: 0 24px 0 75px;
        box-shadow: 0px 4px 8px rgba(81, 111, 144, 0.25);

        svg {
          font-size: 20px;
          color: #253342;
        }

        p {
          font-size: 14px;
          width: 50px;
        }
      }
    }

    .AboutUsAndDetails {
      .container {
        margin: 0 !important;
        max-width: 400px;
      }
    }

    .place-details {
      position: relative;
      max-width: 770px;
      margin-right: 30px;

      .container {
        width: 100%;
        // margin: 0 auto;

        h3 {
          font-size: 22px;
          margin: 0;
          padding: 18px 0;
          color: #253342;
        }
      }
    }

    .ServicesDisplay {
      padding-bottom: 39px;
    }
  }
}

@media (max-width: 1024px) {
  .Place {
    padding-top: 0;
    .mobile-navigation {
      background: #fff;
      position: fixed;
      display: block;
      width: 100%;
      &.not-scrolled {
        opacity: 0;
        pointer-events: none;
        animation: undisplayMobileNav 0.25s linear;
      }
      &.scrolled {
        z-index: 2;
        pointer-events: all;
        // display: block;
        filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.16));
        animation: displayMobileNav 0.5s linear;
        opacity: 1;
      }
      .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;
        height: 54px;
        border-bottom: 1px solid #dfe3eb;
        .left {
          display: flex;
          align-items: center;
          svg {
            width: 27px;
            height: 27px;
          }
          h3 {
            font-weight: bold;
            font-size: 17px;
            color: #101928;
            margin: 0 0 0 10px;
          }
        }
        .favorite {
          svg {
            width: 25px;
            height: 25px;
            &.fav {
              color: red;
            }
          }
        }
      }
      .PlaceNavigation {
        display: flex;
        justify-content: space-between;
        height: 42px;
        padding: 0 30px;
        box-sizing: border-box;
        width: 100%;

        a {
          margin-right: 20px;
        }
      }
    }
    .main {
      flex-direction: column;
      width: 100%;
      padding: 0 !important;
      .go-back {
        display: block;
        position: absolute;
        left: 20px;
        top: 16px;
        z-index: 20;
        svg {
          width: 30px;
          height: 30px;
          color: #fff;
        }
      }

      .place-details {
        min-width: 100% !important;
        margin: 0;
        position: relative;

        .container {
          padding: 0 !important;
          h3 {
            margin-left: 18px;
          }
          .overview {
            .PlacePic {
              height: 300px !important;
            }
          }
          .PlaceName {
            display: none;
          }
          .PlaceNameMobile {
            display: block;
          }
          .ServicesDisplay {
            .Category {
              .Service {
                margin: 0 20px;
                a {
                  margin-right: 0;
                }
                .right {
                  .price {
                    display: none;
                  }
                }
                .description {
                  .price {
                    display: inline-block;
                  }
                }
              }
            }
          }
        }
      }

      .navigation-favourite {
        display: none;
      }
    }
  }
}

@media (max-width: 1200px) {
  .Place {
    .main {
      padding: 0 32px;
    }
    .place-details {
      width: 55%;
    }
  }
}

@media (max-width: 1440px) {
  .Place {
    .main {
      .AboutUsAndDetails {
        max-width: 350px;
        .container {
          margin: 0 !important;
          width: 100%;
        }
      }

      .place-details {
        max-width: 650px;
      }
    }
  }
}

@media (max-width: 768px) {
  .Place {
    .main {
      padding: 0 20px;
    }
  }
}

@keyframes displayMobileNav {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes undisplayMobileNav {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
