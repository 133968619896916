.Suggestion {
  // opacity: 0;
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  width: 100%;
  padding: 25px 0;
  height: auto;
  background: white;
  border-radius: 8px;
  transition: 0.2s;
  pointer-events: all;
  z-index: 2;

  box-shadow: 0px 4px 12px 0px rgba(81, 111, 144, 0.12);

  &.nav {
    padding: 5px 0;
    .button {
      .image {
        width: 38px;
        height: 38px;
        margin-left: 10px;
      }
    }
  }

  .button {
    display: flex;
    align-items: center;
    width: 100%;
    height: 55px;
    cursor: pointer;

    &:hover {
      background: #f5f8fa;
    }

    .image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 43px;
      height: 43px;
      border-radius: 8px;
      background: #eaf0f6;
      margin-left: 32px;
      margin-right: 14px;

      svg {
        font-size: 18px;
        path {
          stroke: #99acc2;
        }
      }
    }

    p {
      font-size: 16px;
      color: #253342;
      margin: 0;
      width: 260px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* number of lines to show */
      -webkit-box-orient: vertical;
      text-align: left;
    }
  }
}
