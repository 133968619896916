.CheckEmail {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    a{
        margin-bottom: 40px;
    }

    h1{
        font-size: 40px;
    }

    .graphic{
        margin-top: 20px;
        margin-bottom: 45px;
    }

    p{
        max-width: 416px;
        font-size: 18px;
        text-align: center;
        color: #33475B;
    }
}

@media (max-width:1024px){
    .CheckEmail{
        height: 100%;
        box-sizing: border-box;
        padding:0 20px;
        
        a{
            padding-top:50px;
            margin-bottom: 0;
        }

        h1{
            font-size: 32px;
        }
       
    }
}