.Discover {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 560px;
  width: 100%;
  // overflow: hidden !important;
  background: url("../../../assets/LandingPage/DiscoverBackground.png")
    no-repeat;
  background-position: center center;
  background-size: cover;
  color: #253342;
  text-align: center;

  .DiscoverContainer {
    // background: #d8f3f4;
    height: 100%;
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;

    h1 {
      font-size: 42px;
      margin: 0;
      margin-top: 70px;
      margin-bottom: 30px;
      color: #fff;
    }

    p {
      font-size: 20px;
      margin: 5px 0;
      margin-bottom: 30px;
    }

    .search {
      background: #f5f8fa;
      position: relative;
      display: flex;
      justify-content: center;
      width: fit-content;
      height: 66px;
      border-radius: 8px;
      margin: 20px auto;
      box-shadow: 0px 4px 12px 0px rgba(124, 152, 182, 0.12);

      &.active {
        input {
          width: 354px;
        }
        .searchButton {
          width: 112px;
          border-radius: 8px;

          span {
            opacity: 1;
            width: 70px;
            transition: 0.3s;
          }
        }
        // input:not(:placeholder-shown) ~ .close {
        //   display: block;
        // }
      }

      &:focus-within {
        ::after {
          opacity: 0;
          transition: 0s !important;
        }
      }

      .input:focus-within ~ .input input {
        width: 354px;
      }

      .searchButton.focused {
        width: 112px;
        border-radius: 8px;

        span {
          opacity: 1;
          width: 70px;
          transition: 0.3s;
        }
      }

      .input {
        position: relative;

        // input:focus:not(:placeholder-shown) ~ .Suggestion {
        //   opacity: 1;
        //   pointer-events: all;
        // }

        label {
          position: absolute;
          left: 32px;
          top: 11px;
          font-size: 12px;
          color: #253342;
          font-weight: 550;
          z-index: 10;
          pointer-events: none;
        }

        .close {
          // display: none;
          width: 22px;
          height: 22px;
          background: #eaf0f6;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 200px;
          cursor: pointer;
          z-index: 200;

          svg {
            position: relative;
            // top: 2px;
            // right: 1px;
            width: 12px;
            height: 12px;
            color: #253342;
            cursor: pointer;
          }
        }

        &:nth-of-type(1) {
          &::after {
            position: absolute;
            content: "";
            height: 41px;
            top: 12px;
            width: 1px;
            background: #cbd6e2;
            left: 355px;
            transition: 0.5s;
          }

          input {
            min-width: 354px;
            padding-right: 50px;
          }

          .close {
            left: 315px;
          }
        }
      }

      // &.active .input:not(:placeholder-shown) .close {
      //   display: block;
      // }

      input {
        // position: relative;
        width: 314px;
        height: 100%;
        padding-left: 32px;
        font-size: 16px;
        border: none;
        box-sizing: border-box;
        border-radius: 8px;
        background: transparent;
        cursor: pointer;
        transition: all 0.3s;
        padding-top: 19px;
        padding-right: 170px;

        &::placeholder {
          color: #99acc2;
          font-size: 15px;
        }

        &:focus {
          background: #fff;
          box-shadow: 0.5px 0px 12px 0px rgba(81, 111, 144, 0.12);
          width: 354px;
        }

        &:not(:placeholder-shown) {
          min-width: 354px;
        }
      }

      .searchButton {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 8px;
        right: 11px;
        width: 48px;
        height: 48px;
        font-size: 15px;
        background: #1a73e8;
        color: white;
        border: none;
        z-index: 5;
        cursor: pointer;
        overflow: hidden;
        transition: 0.3s;

        span {
          opacity: 0;
          width: 0;
          transition: 0.2s;
          font-size: 15px;
        }

        svg {
          background: #1a73e8;
          font-size: 16px;
          path {
            stroke: white;
          }
        }
      }
    }

    .mobile {
      display: none;

      .mobDiscoverSearch {
        display: flex;
        align-items: center;
        padding: 12px 4px 12px 18px;
        justify-content: space-between;
        background: #ffffff;
        box-shadow: 0px 4px 12px rgba(37, 51, 66, 0.2);
        box-sizing: border-box;
        border-radius: 8px;
        width: 310px;
        height: 50px;
        p {
          margin: 0;
          font-size: 16px;
          color: #516f90;
        }
        .searchIconWrapper {
          background: #1a73e8;
          border-radius: 8px;
          width: 42px;
          height: 42px;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            // top: 15px;
            // left: 40px;
            width: 18px;
            height: 18px;
            path {
              stroke: #fff;
            }
          }
        }
      }

      // .SignupInput {
      //   position: relative;
      //   width: 330px;
      //   height: 50px;

      //   input {
      //     display: block;
      //     width: 290px;
      //     max-width: 314px;
      //     height: 50px;
      //     margin: 0 auto;
      //     border-radius: 8px;
      //     padding-left: 50px;
      //     box-sizing: border-box;
      //     background: white;
      //     box-shadow: 0px 4px 6px rgba(124, 152, 182, 0.45);

      //     &::placeholder {
      //       color: #516f90;
      //     }
      //   }

      //   svg {
      //     top: 15px;
      //     left: 40px;
      //     width: 20px;
      //     height: 20px;
      //     path {
      //       stroke: #253342;
      //     }
      //   }
      // }
    }

    .discoverBottom {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin-top: 30px;

      a {
        color: #fff;
      }
      span {
        margin-right: 5px;
        font-size: 15px;
        color: #fff;

        &.bolded {
          font-weight: bold;
          color: #ffc812;
        }
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1081px) {
  .Discover {
    overflow: hidden;
  }
}

@media (max-width: 1024px) {
  .Discover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 380px;
    text-align: left;
    padding: 9px 16px;
    box-sizing: border-box;
    width: auto;
    text-align: center;
    background-position: bottom right;
    background-size: cover;

    .DiscoverContainer {
      // background: white;
      width: 100% !important;
      margin: 0;
      background: none;
      h1 {
        margin-top: 65px;
        padding-top: 18px;
        font-size: 26px;
      }

      p {
        font-size: 16px;
      }

      .search {
        display: none;
      }

      .mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
