.OpeningHours {
  border-bottom: 1px solid #eaf0f6;
  padding: 15px 0;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    cursor: pointer;

    .hoursHeaderCol {
      display: flex;
      align-items: center;

      svg {
        font-size: 20px;
        margin-right: 20px;
        transition: 0.2s;

        &:last-child {
          margin-right: 0;
        }

        &.show {
          transform: rotate(180deg);
        }
      }

      p {
        margin: 0;
        margin-right: 5px;
        font-size: 15px;

        &:nth-of-type(2) {
          margin-right: 15px;
        }
      }
    }
  }

  .times {
    // width: 233px;
    width: calc(100% - 64px);
    margin-left: 44px;
    margin-top: 5px;
    max-height: 0px;
    overflow: hidden;
    transition: 0.3s;
    box-sizing: border-box;

    &.show {
      max-height: 500px;
    }

    .time {
      display: flex;
      justify-content: space-between;

      &.highlight {
        p {
          font-weight: 600;
        }
      }

      p {
        margin: 3px 0;
        font-size: 15px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .OpeningHours {
    .header {
      svg {
        margin-right: 10px !important;

        &:nth-of-type(2) {
          display: none;
        }
      }
    }

    .times {
      width: 86%;
    }
  }
}
@media (max-width: 491px) {
  .OpeningHours {
    .header {
      svg {
        &.arrow {
          margin-right: 2px !important;
        }
      }
    }

    .times {
      width: 80%;
    }
  }
}
