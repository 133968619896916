.BookingCategories {
  padding-bottom: 10px;
  // border-bottom: 1px solid #eaf0f6;

  .Service,
  .Worker {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    p {
      margin: 0;
      font-size: 15px;
    }

    .description {
      display: flex;
      // align-items: center;

      svg {
        margin-right: 21px;
        font-size: 21px;
        color: #101928;
      }

      p {
        font-size: 15px;
        color: #516f90;
        margin: 0;
        padding: 0;
      }

      h4 {
        font-weight: 500;
        font-size: 16px;
        color: #101928;
        margin: 0;
        margin-bottom: 7px;
      }
    }

    .image {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 39px;
      height: 39px;
      background: #eaf0f6;
      border-radius: 6px;
      color: #99acc2;

      img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
      }
    }
  }
}
