.FooterTW {
  background: #f9fafb;
  height: 230px;
  padding: 48px 32px;
  box-sizing: border-box;
  a {
    text-decoration: none;
  }
  .footer-links {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 35px;
    a {
      margin-right: 20px;
      font-size: 16px;
      color: #6b7280;
      text-align: center;
    }
  }
  .footer-social {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    svg {
      color: #6b7280;
      margin-right: 20px;
      width: 22px;
      height: 22px;
    }
  }
  .footer-bottom {
    text-align: center;
    p {
      color: #9ca3af;
    }
  }
}

@media (max-width: 1024px) {
  .FooterTW {
    padding: 24px 32px;
  }
}
