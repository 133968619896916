.PlaceNavigation {
  height: 66px;
  width: 100%;
  z-index: 10;
  background: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #eaf0f6;

  a {
    box-sizing: border-box;
    display: flex;
    align-items: flex-end;
    height: 100%;
    text-transform: uppercase;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    margin-right: 38px;
    padding-bottom: 10px;
    border-bottom: 3px solid transparent;
    text-decoration: none;
    color: #516f90;
    transition: 0.2s;
    cursor: pointer;

    &.active {
      color: #1a73e8;
      border-bottom: 3px solid #1a73e8;
    }
  }
}

@media (max-width: 1024px) {
  .PlaceNavigation {
    display: none;
  }
}
