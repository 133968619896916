.ResetPassword {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  form {
    width: 340px;

    @media only screen and (max-width: 600px) {
      width: 100%;
      padding: 20px;
    }

    .form_header {
      text-align: center;
      img {
        margin: auto;
        margin-bottom: 10px;
      }
      h1 {
        @media only screen and (max-width: 600px) {
          font-size: 30px;
        }

        font-size: 40px;
        font-weight: 700;
        color: #253342;
        line-height: 1.2;
        margin-bottom: 50px;
      }
    }
    .form_body {
      .helper-text {
        display: block;
        margin-bottom: 10px;
        margin-top: 20px;
        font-size: 14px;
        color: rgb(17, 24, 39);
        font-weight: 700;
      }

      button {
        height: 42px;
        width: 100%;
        color: #fff;
        background: #1a73e8;
        border: none;
        border-radius: 8px;
        margin-top: 20px;
        margin-bottom: 21px;
        font-weight: 600;
        font-size: 14px;
      }
      input {
        display: block;
        width: 100%;
        // height: 46px;
        border-radius: 8px;
        border: 1px solid #cbd6e2 !important;
        margin-top: 6px;
        padding: 10px;
        font-size: 15px;
        box-sizing: border-box;

        &:focus {
          border: 2px solid #1a73e8 !important;
        }

        &::placeholder {
          font-size: 16px;
          line-height: 22px;
          color: #99acc2;
        }

        &.error {
          margin-bottom: 0px;
        }
      }

      input:valid,
      textarea:not(:placeholder-shown) {
        border: 1px solid #1a73e8;
      }

      input:hover,
      textarea:hover {
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
      }

      input:disabled {
        background: #eaf0f6;
      }
    }
  }
}
