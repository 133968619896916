.BookCalendar {
  height: 526px;
  width: 100%;
  box-sizing: border-box;

  .arrow-mobile {
    display: none;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 31px;

    h2 {
      text-align: center;
      font-size: 17px;
      color: #33475b;
      padding: 20px 0;
    }

    .arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 38px;
      font-weight: 500;
      font-size: 16px;
      color: #99acc2;
      border-radius: 50%;
      cursor: pointer;
      svg {
        margin: 0 8px;
      }
      &.later {
        color: #1a73e8;
      }
    }
  }

  .week {
    display: grid;
    grid-template-columns: repeat(5, 1fr);

    .Day {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 450px;
      border-left: 1px solid #eaf0f6;
      background: #f5f8fa;

      &:last-of-type {
        border-right: 1px solid #eaf0f6;
      }
      p {
        margin: 0;
      }

      .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100% !important;
        height: 68px !important;
        color: #516f90;
        margin-bottom: 12px;
        background: #ffffff;
        box-shadow: 0px 6px 11px 0px rgba(0, 0, 0, 0.08);
        span {
          font-size: 14px;
        }

        p {
          font-size: 17px;
          font-weight: 600;
          color: #516f90;
        }
      }

      .slots {
        height: 350px;
        overflow: scroll;
        overflow: scroll;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
          display: none;
        }
        // &::-webkit-scrollbar-track {
        //   border-radius: 5px;
        // }

        // &::-webkit-scrollbar {
        //   width: 4px;
        // }

        // &::-webkit-scrollbar-thumb {
        //   border-radius: 10px;
        //   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        //   background: #253342;
        // }

        .available {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 75px;
          height: 45px;
          background: #d2eafc;
          border-radius: 6px;
          border: 1px solid #1a73e8;
          margin: 6px 0;
          color: #1a73e8;
          font-size: 14px;
          font-weight: 700;
          transition: 0.2s;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .BookCalendar {
    height: 100%;
    .header {
      .arrow {
        display: none;
      }
    }
    .week {
      .Day {
        height: calc(100vh - 88px - 100px);
        .slots {
          height: 100%;
          padding-bottom: 75px;
          box-sizing: border-box;
        }
      }
    }
    .arrow-mobile {
      display: block;
      position: absolute;
      bottom: 20px;
      background: #ffffff;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
      border-radius: 50px;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      &.left {
        left: 20px;
      }
      &.right {
        right: 20px;
      }
    }
  }
}

@media (max-width: 420px) {
  .BookCalendar {
    height: 100%;
    .header {
      .arrow {
        display: none;
      }
    }
    .week {
      .Day {
        .slots {
          width: 85%;
          .available {
            width: 100%;
          }
        }
      }
    }
  }
}
