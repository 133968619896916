.DeleteAccount {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(6px);
    position: absolute;
    z-index: -1;
  }

  .slider {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 490px;
    height: 278px;
    background: white;
    border-radius: 6px;
    overflow: hidden;
    box-shadow:0px 0px 25px 0px rgba(81,111,144,0.3);

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 68px;
      background: #f2545b;
      color: #fff;

      p {
        margin-left: 31px;
        font-weight: 700;
        color: #fff;
        font-size: 18px;
      }

      svg {
        width: 30px;
        height: 30px;
        margin-right: 16px;
        cursor: pointer;
      }
    }
  }

  .container {
    padding: 27px 26px;

    p {
      font-size: 16px;
      margin: 0;
      margin-bottom: 23px;
    }

    .buttons {
      display: flex;
      width: 100%;
      padding-top: 20px;
      // padding-bottom: 32px;

      button {
        background: #f2545b;
        height: 42px;
        width: 215px;
        border-radius: 8px;
        color: #fff;
        font-size: 14px;
        border: 1px solid transparent;
        box-sizing: border-box;

        &:nth-of-type(2) {
          width: 128px;
          margin-left: 19px;
          color: #516f90;
          background: #f5f8fa;
          border: 1px solid #cbd6e2;
        }
      }
    }
  }
}


@media (max-width:1024px){
  .DeleteAccount{
    .slider{
      width: 350px;
      height: auto;
    }
  }
}