.AboutUsAndDetails {
  width: 402px;
  position: sticky;
  top: 25px;
  align-self: flex-start;
  background: #f5f8fa;
  border-radius: 4px;
  padding: 56px;

  .container {
    padding-top: 32px;
    max-width: 331px;
    margin: 0 auto;

    .mapHelper {
      width: 80%;
      height: 150px;
      display: block;
      margin: 0 auto;
      text-align: center;
      position: absolute;
      top: 70px;
      left: 0;
      right: 0;
      z-index: 8000;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;

      .locationHelper {
        margin-top: 5px;
        display: flex;
        border: 1px solid #253342;
        justify-content: space-between;
        background-color: #fff;
        align-items: center;
        padding: 8px 13px;
        border-radius: 8px;

        p {
          font-size: 12px;
          margin-right: 10px;
        }
        .direction {
          display: flex;
          justify-content: center;
          border-left: 1px solid #253342;
          padding-left: 5px;
        }
      }
    }

    .mapDisplay {
      height: 150px;
      border-radius: 8px;
      margin-bottom: 15px;
      position: relative;
    }

    h5 {
      font-size: 20px;
      margin: 0;
      margin-bottom: 9px;
    }

    p {
      margin: 0;
    }

    .about-us {
      padding: 20px 0;
      margin-bottom: 10px;
      border-top: 1px solid #eaf0f6;
      border-bottom: 1px solid #eaf0f6;

      h5 {
        font-size: 16px;
        font-weight: 600;
        color: #253342;
      }

      p {
        font-weight: normal;
        font-size: 14px;
        color: #33475b;
        line-height: 28px;
      }

      a {
        display: block;
        color: #1a73e8;
        text-decoration: none;
        font-size: 13px;
        margin-top: 5px;
      }
    }

    .details-and-contact {
      .with-icon {
        padding: 8px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
        border-radius: 6px;
        border-radius: 8px;
        margin-bottom: 13px;
        margin-top: 24px;
        height: 50px;
        box-sizing: border-box;
        cursor: pointer;
        &:last-child {
          margin-top: 0;
        }

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 12px;
          margin-right: 10px;

          svg {
            font-size: 20px;
            // color: #1A73E8;
            color: #253342;
          }
        }

        h5 {
          margin: 0;
          font-size: 18px;
        }

        span {
          display: block;
          margin: 2px 0;
        }

        a {
          color: #253342;
          height: 40px;
          text-decoration: none;
          font-weight: 500;
        }
      }
    }

    // button {
    //   display: block;
    //   width: 173px;
    //   height: 42px;
    //   background: #d8f3f4;
    //   border-radius: 8px;
    //   color: #1A73E8;
    //   font-size: 14px;
    //   font-weight: bold;

    //   margin: 30px auto;
    // }
  }
}

@media (max-width: 1024px) {
  .AboutUsAndDetails {
    display: none;
    // position: relative;
    // width: 100%;
    // top: 0;
    // .container {
    //   padding: 0 20px;
    //   padding-top: 80px;
    //   max-width: 618px;
    // }
  }
}
