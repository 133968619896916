.PlaceName {
  margin-top: 30px;
  h1 {
    margin: 0;
    font-size: 24px;
  }

  h5 {
    display: none;
  }

  span {
    color: #516f90;
    font-size: 15px;
  }

  .rating {
    display: flex;
    align-items: center;

    p {
      font-size: 16px;
      font-weight: bold;
      margin: 0;
      margin-right: 7px;
      margin-left: 6px;
      color: #101928;
    }

    .stars {
      display: flex;
      align-items: center;
      margin-right: 5px;

      svg {
        fill: #ea9c46;
        margin-top: 3px;
        width: 15px;
        height: 15px;
      }
    }
  }

  // padding-bottom: 24px;
  // border-bottom: 1px solid #EAF0F6;
}
