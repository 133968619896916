.ProfileOptions {
  position: relative;
  display: inline;
  cursor: pointer;
  z-index: 0;

  .display {
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 77px;
    height: 42px;
    border-radius: 32px !important;
    padding-right: 5px !important;
    border: 1px solid #cbd6e2;
    box-sizing: border-box;

    .hamburger {
      margin: 0;
      margin-top: 12.5px;
      width: 16px;
      height: 12px;
      .line {
        background: #101928;
      }
      // height: 11px;
    }

    svg {
      color: #101928;
      font-size: 26px;
      margin-bottom: 3px;
      pointer-events: none;
    }
  }

  .popup {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    top: 45px;
    right: 0;
    position: absolute;
    width: 209px;
    height: auto;
    border-radius: 8px;
    background: white;
    border: 1px solid #cbd6e2;
    box-sizing: border-box;
    padding: 4px 0;
    z-index: 1000;

    hr {
      width: 100%;
      box-sizing: border-box;

      border: 1px solid #eaf0f6;
    }

    .title {
      background: #f5f8fa;

      p {
        color: #516f90;
        font-size: 12px;
        margin: 0;
        padding: 10px 16px;
        text-transform: uppercase;
      }
    }

    a,
    .logout {
      display: flex;
      align-items: center;
      margin: 0;
      height: 35px;
      box-sizing: border-box;
      margin: 0;
      border-radius: 4px;
      padding-left: 16px;

      p {
        margin: 0;
        font-size: 14px;
      }

      svg {
        font-size: 19px;
        // padding: 0 11px;
        padding-right: 12px;
        margin: 0;
        margin-right: 3px;
        margin-bottom: 2px;
        color: black;
      }

      &:hover {
        background: #f5f8fa;
      }
    }

    .logout {
      padding-left: 14px;

      svg {
        margin-right: 5px;
      }
    }
  }
}
