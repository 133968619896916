.PlaceNameBooking {
  // padding-bottom: 21px;
  display: flex;
  align-items: flex-start;
  margin-top: 24px;
  svg {
    width: 24px;
    height: 24px;
    margin-right: 16px;
    color: #101928;
  }
  .title {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    p {
      font-size: 15px;
      color: #516f90;
      margin: 0;
      padding: 0;
    }

    h4 {
      font-weight: 500;
      font-size: 16px;
      color: #101928;
      margin: 0;
      margin-bottom: 7px;
    }

    button {
      color: #1a73e8;
      margin-top: 5px;
      padding: 0;
    }
  }
}

@media (max-width: 1024px) {
  .PlaceNameBooking {
    .title {
      display: block;

      p {
        margin-top: 10px;
      }

      button {
        margin-top: 10px;
      }
    }
  }
}
