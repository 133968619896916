.Areas {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 50px;
  margin-bottom: 80px;

  a {
    text-decoration: none;
  }

  .Area {
    display: block;
    position: relative;
    -webkit-box-flex: 1;
    flex-grow: 1;
    border-radius: 4px;
    transition: 0.25s ease-in-out;
    box-shadow: 0px 4px 8px 0px rgba(124, 152, 182, 0.25);
    overflow: hidden;
    text-decoration: none;
    color: #253342;
    text-align: center;

    .image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 108px;
      overflow: hidden;

      img {
        min-height: 100%;
        width: 100%;
      }
    }

    .description {
      h3 {
        font-size: 15px;
        font-weight: 500;
      }
    }

    &:hover {
      box-shadow: 0px 4px 8px 4px rgba(124, 152, 182, 0.25);
    }
  }
}

@media (max-width: 1024px) {
  .Areas {
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 20px;
  }
}

@media (max-width: 768px) {
  .Areas {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 596px) {
  .Areas {
    grid-template-columns: repeat(2, 1fr);
  }
}
