.BookingPlace {
  padding-top: 70px;
  .main {
    display: flex;
    justify-content: space-between;
    max-width: 1120px;
    margin: 0 auto;
    height: 100%;
    box-sizing: border-box;
    padding: 0 20px;

    .book {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 510px;
      min-width: 510px;
      height: 50px;
      border-radius: 8px;
      text-transform: uppercase;
      background: #1a73e8;
      color: white;
      font-size: 16px;
      font-weight: bold;

      &.upcoming {
        background: #f64e60;
      }

      svg {
        font-size: 25px;
        margin-right: 10px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .BookingPlace {
    .main {
      display: block;
      width: 100%;

      .book {
        margin: 0 auto;
        min-width: auto;
      }

      .AboutUsAndDetails {
        max-width: 510px;
        margin: 0 auto;
      }
    }
  }
}
