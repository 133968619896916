.StoreSwiper {
  width: 100%;
  height: 140px;

  .swiper-button-prev {
    color: #000 !important;
    position: absolute;
    top: 55%;
    transform: translateY(-55%);
    left: 0;
    z-index: 20;
    width: 50px !important;
    height: 50px !important;
    background: url("../../../assets/Book/left.png") center center no-repeat !important;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
  .swiper-button-next {
    position: absolute;
    top: 55%;
    transform: translateY(-55%);
    right: 0;
    z-index: 20;
    width: 50px;
    height: 50px;
    background: url("../../../assets/Book/right.png") center center no-repeat !important;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .swiper-pagination {
    position: absolute;
    bottom: 20px;
    z-index: 2000;
    width: 100px;
    height: 30px;
    .swiper-pagination-bullet {
      z-index: 2000;
      width: 10px !important;
      height: 10px !important;
      background: #fff;
    }
  }

  .swiper-slide {
    width: 100%;
  }
  .SearchPicture {
    overflow: hidden;
    height: 170px;
    width: 100%;
    min-height: 100%;
    img {
      // min-height: 170px;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
