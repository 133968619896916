.Personell {
  padding-bottom: 40px;
  .person {
    display: flex;
    border-bottom: 1px solid #eaf0f6;
    align-items: center;
    justify-content: space-between;

    .about {
      display: flex;
      align-items: center;

      margin-top: 15px;
      margin-bottom: 17px;

      .img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 63px;
        height: 63px;
        background: #eaf0f6;
        border-radius: 6px;
        margin-right: 20px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 6px;
        }

        svg {
          width: 21px;
          height: 26px;
          color: #99acc2;
        }
      }

      .description {
        h5,
        p,
        span {
          margin: 0;
        }

        h5 {
          font-size: 16px;
          margin-bottom: 3px;
        }

        p,
        span {
          color: #516f90;
          font-size: 12px;
        }

        p {
          margin-bottom: 0px;
        }

        .rating {
          display: flex;
          align-items: center;

          .Stars {
            margin-right: 9px;
          }
        }
      }
    }
    .choose {
      width: 84px;
      height: 42px;
      border-radius: 8px;
      background: #d8f3f4;
      color: #1a73e8;
      font-size: 14px;
      font-weight: bold;
    }

    &.selectable {
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        transform: scale(1.05);
      }
    }

    .select {
      font-size: 20px;
      color: #253342;
    }
  }
}

@media (max-width: 1024px) {
  .Personell {
    .person {
      .about {
        .description {
          .rating {
            span .resensioner {
              display: none;
            }
          }
        }
      }
    }
  }
}
