.Booking {
  padding-top: 70px;
  min-height: 100vh;
  // padding-bottom: 50px;
  box-sizing: border-box;
  background: #f5f8fa;
  .sticky-container {
    background: white;
    box-shadow: 0px 4px 12px 0px rgba(81, 111, 144, 0.12);
  }
  .sticky {
    box-sizing: border-box;
    height: 56px;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 80px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    z-index: 10;

    p {
      font-size: 16px;
      font-weight: 700;
      // margin-left: 56px;
    }

    .links {
      justify-content: space-around;
      display: flex;
      align-items: center;

      button {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: bold;
        margin: 0;
        padding-bottom: 2px;
        border-bottom: 2px solid transparent;

        &.active {
          color: #1a73e8;
          border-bottom: 2px solid #1a73e8;
          height: 100%;
        }
      }
    }
  }

  .out-container {
    max-width: 1060px;
    margin: 0 auto;

    .container {
      max-width: 760px;
      // margin-left: 56px;
      margin: 0 auto;
      margin-top: 44px;
      box-sizing: border-box;
    }
  }
}

@media (max-width: 1200px) {
  .Booking {
    .sticky {
      padding: 0 32px;
    }
  }
}

@media (max-width: 1024px) {
  .Booking {
    .sticky {
      position: sticky;
      top: 60px;
      grid-template-columns: 1fr;
      padding: 0;
      .title {
        display: none;
      }
    }

    .out-container {
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;

      .container {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
      }
    }
  }
}
