.PopularPlaces {
  display: grid;
  grid-template-columns: repeat(4, minmax(0px, 1fr));
  grid-column-gap: 16px;
  margin-bottom: 50px;
}
@media (max-width: 1024px) {
  .PopularPlaces {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .PopularPlaces {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 374px) {
  .PopularPlaces {
    grid-template-columns: repeat(1, 1fr);
  }
}
