.ReviewProgress {
  width: 250px;
  height: 5px;
  margin-top: 5px;
  background: #eaf0f6;
  margin-left: 10px;
  display: flex;
  border-radius: 5px;

  .reviewFilled {
    background-color: #7c98b6;
    border-radius: 5px;
  }
}

@media (max-width: 1440px) {
  .ReviewProgress {
    width: 150px;
  }
}

@media (max-width: 1024px) {
  .ReviewProgress {
    width: 39vw;
  }
}
