.CanceledAppointment {
  height: auto;
  //   height: 90vh;
  position: relative;
  width: 100%;
  //   padding-top: 60px;
  padding-top: 100px;
  padding-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f2f9;

  .info {
    width: 670px;
    background-color: white;
    border-radius: 10px;
    display: grid;
    justify-content: center;
    text-align: center;

    @media only screen and (max-width: 768px) {
      width: 100%;
      height: auto;
      margin: 10px;
    }

    h1 {
      color: #253342;
      margin-top: 30px;

      @media only screen and (max-width: 1200px) {
        font-size: 25px;
      }
    }
    p {
      margin-top: 5px;
      font-size: 20px;
      @media only screen and (max-width: 1200px) {
        font-size: 15px;
      }
    }

    &_single {
      padding-left: 180px;
      padding-right: 180px;

      @media only screen and (max-width: 768px) {
        padding-left: 30px;
        padding-right: 30px;
      }

      p {
        margin: 15px;

        @media only screen and (max-width: 1200px) {
          margin: 10px;
        }
      }

      .title {
        color: #33475b;
        font-weight: 700;
      }
    }
    &_single:last-of-type {
      padding-left: 140px;
      padding-right: 140px;
      margin-bottom: 20px;

      @media only screen and (max-width: 768px) {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
    button {
      height: 42px;
      width: 100%;
      color: #fff;
      background: #1a73e8;
      border: none;
      border-radius: 8px;
      margin-top: 20px;
      margin-bottom: 21px;
      font-weight: 300;
      font-size: 20px;

      @media only screen and (max-width: 1200px) {
        font-size: 15px;
      }
    }
  }
}
