.PlacePic {
  .swiper-button-prev {
    color: #000 !important;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    z-index: 20;
    width: 50px;
    height: 65px;
    background: url("../../../assets/Book/slideLeft.png") right center no-repeat !important;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
  .swiper-button-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    z-index: 20;
    width: 50px;
    height: 65px;
    background: url("../../../assets/Book/slideRight.png") left center no-repeat !important;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .PlacePicture {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 500px;
    // border-radius: 8px;
    overflow: hidden;
    // margin: 24px 0;
    cursor: pointer;

    img {
      min-height: 100%;
      // min-width: 100%;
      object-fit: cover;
    }
  }
  position: relative;
  .favorite {
    position: absolute;
    top: 43px;
    right: 33px;
    z-index: 21;
    svg {
      width: 36px;
      height: 36px;
      transform: scale(1.5);

      // stroke: red;
      &.favorited {
        animation: 500ms ease-in-out 0s 1 normal none running addToFav;
        path {
          fill: red;
          &:last-child {
            fill: #fff;
          }
        }
      }
      &.fav {
        path {
          fill: red;
          &:last-child {
            fill: #fff;
          }
        }
      }
    }
  }
}
// }
@media (max-width: 1024px) {
  .PlacePic {
    .swiper-button-prev {
      color: #000 !important;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      z-index: 20;
      width: 40px;
      height: 30px;
      background: url("../../../assets/Book/slideLeft.png") right center
        no-repeat !important;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
    }
    .swiper-button-next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      z-index: 20;
      width: 40px;
      height: 30px;
      background: url("../../../assets/Book/slideRight.png") left center
        no-repeat !important;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
    }
    .swiper-slide {
      width: 100% !important;
      .PlacePicture {
        height: 300px;
      }
    }
    .favorite {
      display: none;
    }
  }
}

@keyframes addToFav {
  0% {
    transform: scale(1.5);
  }
  25% {
    transform: scale(1.8);
  }
  50% {
    transform: scale(1.6);
  }
  75% {
    transform: scale(1.8);
  }
  100% {
    transform: scale(1.5);
  }
}
