.BookingDate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 24px 0;

  .date {
    display: flex;
    align-items: center;

    svg {
      margin-right: 21px;
      width: 24px;
      height: 24px;
    }

    p {
      font-weight: 700;
      margin: 0;
      color: #253342;
    }
  }

  button {
    color: #1a73e8;
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 1024px) {
  .BookingDate {
    display: block;

    button {
      margin-left: 37px;
    }
  }
}
