.LoginForm {
  margin-top: 24px;
  width: 100%;

  .SignupInput {
    label {
      font-weight: 600;
    }

    input {
      margin-bottom: 20px;
    }
  }

  .keepForgot {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      color: #1a73e8;
      text-decoration: none;
      font-size: 15px;
    }
  }

  .keep {
    display: flex;
    align-items: center;
    text-align: left;
    cursor: pointer;

    .checkbox {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 11px;
      width: 15px;
      height: 13px;
      border: 1px solid #cbd6e2;
      border-radius: 2px;

      svg {
        color: white;
        font-size: 13px;
      }

      &.active {
        background: #1a73e8;
        border-color: transparent;
      }
    }
    label {
      font-size: 12px;
      color: #33475b;
      margin-top: 2px;
      cursor: pointer;
    }
  }

  button {
    height: 48px;
    width: 100%;
    color: #fff;
    background: #1a73e8;
    border: none;
    border-radius: 4px;
    margin-top: 20px;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 14px;
  }
}

@media (max-width: 1024px) {
  .LoginForm {
    width: 100%;
  }
}
