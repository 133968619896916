.Profile {
  padding-top: 70px;
  .container {
    margin-top: 42px;
    max-width: 1440px;
    padding: 0 80px;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;

    .main {
      width: 676px;
    }

    hr {
      border: 1px solid #eaf0f6;
    }

    .grid {
      margin-top: 29px;
      display: grid;
      grid-column-gap: 26px;
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: 33px;

      .SignupInput input:valid,
      .SignupInput textarea:not(:placeholder-shown) {
        border: 1px solid #cbd6e2;
      }

      button {
        padding: 0;
        text-align: left;
        width: 120px;
        font-size: 12px;
        font-weight: 600;
        color: #1a73e8;
      }
    }

    .update {
      width: 154px;
      height: 42px;
      border-radius: 8px;
      background: #1a73e8;
      color: white;
      font-size: 14px;
      font-weight: 600;
    }

    .changes {
      border-top: 1px solid #eaf0f6;
      margin-top: 27px;

      .change {
        padding: 25px 30px 35px 0;
        border-bottom: 1px solid #eaf0f6;

        label {
          font-size: 16px;
          font-weight: 700;
          color: #4a5568;
        }

        .type {
          margin-top: 11px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          p {
            margin: 0;
          }
        }

        button {
          font-size: 14px;
          font-weight: bold;
          color: #1a73e8;
          margin-bottom: 2px;
          &.delete {
            color: #f25767;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .Profile {
    .container {
      width: 100%;
      padding: 0 20px;
      margin: 0;
      box-sizing: border-box;

      .main {
        width: 100%;
      }

      .grid {
        display: block;

        button {
          width: 100%;
        }
      }

      .changes {
        .change {
          .type {
            display: block;
          }
        }

        button {
          padding: 0;
          margin-top: 15px;
        }
      }
    }
  }
}

.my-popup-enter {
  .overlay {
    opacity: 0;
  }
  .slider {
    transform: translate(-50%, -100%) !important;
    opacity: 0;
  }
}

.my-popup-enter-active {
  .overlay {
    opacity: 1;
    transition: 0.3s;
  }

  .slider {
    transform: translate(-50%, -50%) !important;
    transition: 0.3s;
    opacity: 1;
  }
}

.my-popup-exit {
  .overlay {
    opacity: 1;
  }

  .slider {
    transform: translate(-50%, -50%) !important;
    opacity: 1;
  }
}
.my-popup-exit-active {
  .overlay {
    opacity: 0;
    transition: 0.3s;
  }

  .slider {
    transform: translate(-50%, -100%) !important;
    transition: 0.3s;
    opacity: 0;
  }
}
