.ProfilePicture {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 123px;

  .profile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 90px;
    background: #eaf0f6;
    border: 1px dashed #99acc2;
    border-radius: 6px;
    overflow: hidden;

    &.image {
      background: white;
      border: 1px solid #cbd6e2;
    }

    img {
      width: 100%;
    }

    svg {
      fill: #99acc2;
      width: 40px;
      height: 40px;
    }
  }

  .description {
    .inline {
      position: relative;
      input {
        position: absolute;
        cursor: pointer;
        width: 0;
        border-left: 70px solid transparent;
        border-right: 70px solid transparent;
        opacity: 0;
      }
      button {
        padding: 0;
        font-weight: 600;
        color: #99acc2;
        margin-left: 15px;

        &:nth-of-type(1) {
          color: #1a73e8;
          margin-left: 0;
          padding-right: 15px;
          border-right: 1px solid #eaf0f6;
        }
      }
    }
  }

  &.settings {
    justify-content: flex-start;

    .profile {
      order: 1;
      margin-right: 32px;
    }
    .description {
      order: 2;

      label {
        display: none;
      }
    }
  }
}

@media (max-width: 1024px) {
  .Profile .settings {
    display: block;
    height: auto;
    padding: 36px 0 19px 0;

    .profile {
      margin: 0 auto;
      width: 75px;
      height: 75px;
      margin-bottom: 22px;
    }

    .description {
      .inline {
        button {
          font-size: 14px;
        }
      }
    }
  }
}
