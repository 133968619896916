.BookNavigation {
  width: 100%;
  height: 132px;
  background: #253342;
  padding-top: 31px;
  box-sizing: border-box;

  .container {
    max-width: 920px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    color: #fff;
    box-sizing: border-box;
    padding-top: 23px;

    svg {
      font-size: 23px;
      cursor: pointer;
    }

    .back {
      position: absolute;
      left: -40px;
    }

    .title {
      display: flex;

      svg {
        margin-right: 20px;
      }

      span {
        color: #cbd6e2;
        font-size: 15px;
      }

      h2 {
        font-size: 30px;
        margin: 0;
      }
    }
  }
}
