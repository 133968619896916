.CancellationModal {
  position: fixed;
  top: 0px;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 30;
  background-color: #fff;
  animation: slide-up2-fade-in ease 0.5s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  animation-fill-mode: forwards; /*when the spec is finished*/
  -webkit-animation: slide-up2-fade-in ease 0.5s;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-fill-mode: forwards; /*Chrome 16+, Safari 4+*/
  -moz-animation: slide-up2-fade-in ease 0.5s;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -moz-animation-fill-mode: forwards; /*FF 5+*/
  -o-animation: slide-up2-fade-in ease 0.5s;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -o-animation-fill-mode: forwards; /*Not implemented yet*/
  -ms-animation: slide-up2-fade-in ease 0.5s;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
  -ms-animation-fill-mode: forwards; /*IE 10+*/
  opacity: 0;
  opacity: 1\9;
  .notesContent {
    text-align: center;
    padding: 16px;
    box-sizing: border-box;

    .comment {
      display: grid;
      text-align: left;
      margin-top: 20px;
      margin-bottom: 50px;

      label {
        color: #33475b;
        font-weight: 700;
        font-size: 14px;
        display: block;
        text-align: left !important;
      }

      textarea {
        display: block;
        width: 100%;
        height: 100px;
        border-radius: 4px;
        border: 1px solid #cbd6e2;
        margin-top: 6px;
        margin-bottom: 16px;
        // padding: 0 16px;
        font-size: 15px;
        box-sizing: border-box;
      }
    }
  }
  .header {
    background: #253342;
    display: flex;
    height: 64px;
    align-items: center;
    justify-content: center;
    padding: 0 22px;
    .back {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      cursor: pointer;
      svg {
        color: #fff;
        font-size: 20px;
      }
    }
    h3 {
      font-weight: bold;
      font-size: 16px;
      color: #ffffff;
    }
  }
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
    margin: 0;
  }
  .termsBottom {
    position: fixed;
    display: flex;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 71px;
    justify-content: space-between;
    gap: 150px;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px -4px 12px -1px rgba(0, 0, 0, 0.08);
    button {
      //   width: 343px;
      width: 100%;
      height: 42px;

      border-radius: 6px;
      font-weight: 600;
      font-size: 14px;
      color: #ffffff;
      margin: 10px;
      &:first-child {
        background: transparent;
        color: #33475b;
        border: 1px solid #cbd6e2;
      }

      &:last-child {
        background: #1a73e8;
      }
    }
  }
}
.overlay {
  display: none;
}

@media (min-width: 1024px) {
  .CancellationModal {
    top: 20%;
    left: 50%;
    transform: translateX(-50%) !important;
    max-width: 600px;
    height: auto;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0px -2px 8px 2px rgba(81, 111, 144, 0.12);
    animation: slide-up2-fade-in-desktop ease 0.5s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    animation-fill-mode: forwards; /*when the spec is finished*/
    -webkit-animation: slide-up2-fade-in-desktop ease 0.5s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -webkit-animation-fill-mode: forwards; /*Chrome 16+, Safari 4+*/
    -moz-animation: slide-up2-fade-in-desktop ease 0.5s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -moz-animation-fill-mode: forwards; /*FF 5+*/
    -o-animation: slide-up2-fade-in-desktop ease 0.5s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -o-animation-fill-mode: forwards; /*Not implemented yet*/
    -ms-animation: slide-up2-fade-in-desktop ease 0.5s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
    -ms-animation-fill-mode: forwards; /*IE 10+*/
    opacity: 0;
    opacity: 1\9;
  }
  .overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(20.54px);
  }
}

@keyframes slide-up2-fade-in {
  0% {
    opacity: 0;
    transform: translate(0px, 500px);
  }
  100% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes slide-up2-fade-in-desktop {
  0% {
    opacity: 0;
    transform: translate(-50%, 500px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0px);
  }
}
