.SortBy {
  position: relative;
  // width: 171px;
  width: auto;
  height: 30px;
  border-radius: 50px;
  background: white;
  border: 1px solid #cbd6e2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px 0 17px;
  box-sizing: border-box;
  cursor: pointer;

  span {
    font-size: 13px !important;
  }

  p {
    font-size: 13px;
    margin-left: 10px;
  }

  svg {
    font-size: 19px;
  }

  .filters {
    position: absolute;
    background: white;
    width: 100%;
    height: 84px;
    border-radius: 12px;
    border: 1px solid #cbd6e2;
    top: 32px;
    left: 0;
    box-sizing: border-box;
    padding: 5px 18px;

    .choice {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;
      &:nth-of-type(1) {
        border-bottom: 1px solid #eaf0f6;
      }
      p {
        margin: 0;
        font-size: 16px;
        color: #33475b;
      }

      .checkbox {
        width: 20px;
        height: 20px;
        border-radius: 3px;
        border: 1px solid #33475b;

        svg {
          color: white;
        }
      }

      &.active .checkbox {
        background: #33475b;
      }
    }
  }
}
