.Review {
  background: #f5f8fa;
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 19px;
  padding: 20px 30px 20px 30px;
  box-sizing: border-box;
  position: relative;

  // REVIEW TEXT
  .reviewText {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; /* number of lines to show */
    -webkit-box-orient: vertical;
    &.showTwo {
      -webkit-line-clamp: 2;
    }
  }

  .seeMore {
    font-weight: 500;
    font-size: 14px;
    color: #1a73e8;
    cursor: pointer;
    // width: 30%;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    position: relative;

    .description {
      display: flex;

      .nameMobile,
      .dateMobile,
      .imgMobile {
        display: none;
      }

      .image {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #99acc2;
        min-width: 40px;
        height: 40px;
        border-radius: 6px;
        background: #eaf0f6;
        margin-right: 10px;
        margin-top: 10px;
        p {
          display: none;
        }
      }

      .name {
        margin-top: 6px;
        margin-right: 7px;
        p {
          font-size: 16px;
          font-weight: bold;
          margin: 0;
        }

        span {
          font-size: 12px;
          margin: 0;
          color: #ea9c46;
          letter-spacing: 2px;
        }
      }

      .date {
        font-size: 12px;
        font-weight: 400;
        margin-top: 36px;
        color: #516f90;
      }
    }

    .verified-report {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 3px;
      position: relative;
    }
  }
  .verified-report-mobile {
    display: none;
  }

  .verified {
    display: flex;
    align-items: center;
    color: #8860b1;
    margin-bottom: 13px;
    position: relative;
    p {
      margin: 0;
      color: #8860b1;
      font-size: 11px;
      margin-right: 5px;
    }
  }

  .report {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 20px;
    width: 62px;
    border-radius: 50px;
    border: 1px solid #99acc2;
    cursor: pointer;

    span {
      font-size: 10px;
      color: #99acc2;
    }

    svg {
      color: #99acc2;
      font-size: 10px;
    }
  }

  .verificationCloud {
    background: #d8f3f4;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 5px 18px;
    max-width: 330px;
    min-height: 100px;
    position: absolute;
    top: -100px;
    right: 20px;
    display: none;

    &::after {
      background-color: #d8f3f4;
      box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.25);
      content: "\00a0";
      display: block;
      height: 20px;
      right: 10px;
      position: absolute;
      bottom: 0;
      transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      width: 20px;
      margin-bottom: -9px;
    }

    &.showCloud {
      display: block;
    }

    p {
      font-size: 12px;
      line-height: 20px;
      color: #33475b;
    }
  }
}

@media (max-width: 1024px) {
  .Review {
    .flex {
      .description {
        width: 100%;
        justify-content: space-between;
        .image {
          display: none;
        }
        .name {
          display: none;
        }
        .date {
          display: none;
        }
        .imgMobile {
          display: flex;

          .image {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          p {
            font-size: 16px;
            font-weight: bold;
            margin-left: 2px;
          }
        }
        .dateMobile {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          span {
            font-size: 12px;
          }
        }
      }

      .verified-report {
        display: none;
      }
    }

    .verified-report-mobile {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .verified {
        margin-bottom: 0;
      }
    }

    .verificationCloud {
      top: 65px;
      // bottom: 50px;
      // right: 20px;
      right: 18%;

      &::after {
        left: 12px;
      }
    }
  }
}

@media (max-width: 462px) {
  .verificationCloud {
    right: 5% !important;

    &::after {
      left: 50px !important;
    }
  }
}

@media (max-width: 351px) {
  .verificationCloud {
    top: 50px !important;
  }
}
