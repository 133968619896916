.AboutUs {
    .container{
        max-width: 720px;
        margin:0 auto;
        text-align: center;

        h2{
            font-size: 32px;
            margin:0;
            margin-bottom: 23px;
        }

        p{
            font-size: 16px;
            line-height: 26px;
        }
    }

    .header {
        height: 500px;
        background: #F5F8FA; 

        .container{
            padding-top: 75px;
            display:flex;
            justify-content: space-between;
            text-align: left;

            .text{
                margin-right: 92px;
            }

            .img{
                width: 100%;
            }
        }
    }

    .spotlight{
        height: 475px;
        background:url('../../../assets/AboutUs/Spotlight.png') no-repeat center center;
        background-size: cover;

        .container{
            padding-top: 169px;
        }
    }

    .team {
        height: 520px;
        background:#F5F8FA;
        .container{
            max-width: 760px;
        }

        h2{
            padding-top: 72px;
      
        }

        p{
            font-size: 20px;
        }
    }
}

@media (max-width:1024px){
    .AboutUs{
        .header{
            height: 100%;
            padding-bottom: 25px;
            .container{
                display: block;
                text-align: center;
                padding-top: 45px;
                padding-left: 17px;
                padding-right: 17px;

                .text{
                    margin:0;
                }
                img{
                    display: none;
                }
            }
        }

        .spotlight{
            height: 100%;
            background-image:url('../../../assets/AboutUs/SpotlightMobile.png');
            padding:0 17px;

            .container{
                padding-top:45px;
                padding-bottom: 69px;
            }
        }

        .team{
            height: 100%;
            padding:0 17px;
        }

    }
}