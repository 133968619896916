.SinglePopularPlace {
  // height: 100%;
  // width: 100%;
  text-decoration: none;
  cursor: pointer;
  display: block;
  position: relative;
  -webkit-box-flex: 1;
  flex-grow: 1;
  border-radius: 4px;
  background-color: transparent;
  overflow: hidden;
  transform: translateZ(0px);
  transition: transform 300ms ease 0s;
  padding-bottom: 24px;
  &:hover {
    img {
      filter: brightness(0.7);
    }
  }
  img {
    border-radius: 4px;
    max-height: 170px;
    width: 100%;
    transition: 0.25s ease-in-out;
    object-fit: cover;
  }
  h1 {
    letter-spacing: 0px;
    margin: 0px;
    min-width: 0px;
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    color: rgb(16, 25, 40);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  p {
    letter-spacing: 0px;
    min-width: 0px;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    color: rgb(135, 140, 147);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 0px 0px 2px;
  }
  .flex {
    display: flex;
    align-items: center;
    span {
      margin: 0px 8px 0px 8px;
      min-width: 0px;
      font-size: 15px;
      font-weight: 700;
      line-height: 21px;
      display: inline;
      color: #101928;
    }
    p {
      margin: 0;
      &.small-rate {
        display: none;
      }
      &.big-rate {
        display: block;
      }
    }
    svg {
      color: #ffc830;
      width: 19px;
      height: 19px;
    }
  }
  .featured {
    margin-top: 8px;
    padding: 4px 12px;
    border-radius: 4px;
    margin-right: 8px;
    background: rgb(205, 228, 255);
    width: 70px;
    color: rgb(3, 122, 255);
    font-size: 13px;
    font-weight: 700;
    line-height: 18px;
    color: rgb(3, 122, 255);
    text-transform: uppercase;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .SinglePopularPlace {
    .flex {
      p {
        &.big-rate {
          display: none;
        }
        &.small-rate {
          display: block;
        }
      }
    }
  }
}
