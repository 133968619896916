.ServicesDisplay {
  text-align: left;
  .Category {
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 48px;
      background: #f5f8fa;
      padding: 0 18px;
      box-shadow: border-box;
      cursor: pointer;

      h5 {
        pointer-events: none;
        font-size: 16px;
        font-weight: 600;
        color: #253342;
      }

      svg {
        pointer-events: none;
        font-size: 27px;
        cursor: pointer;
        transition: 0.3s;
        transform: rotate(180deg);
      }

      &.expand ~ .Service {
        max-height: 500px;
        opacity: 1;
        transition: 0.5s;
      }

      &.expand svg {
        transform: rotate(0);
      }
    }
    margin-bottom: 5px;
  }

  .Service {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    max-height: 0;
    transition: 0.2s;
    opacity: 0;

    .description {
      margin: 17px 18px;
      box-sizing: border-box;

      .price {
        display: none;
      }
    }

    p {
      font-size: 16px;
      color: #253342;
      margin: 0;
    }

    span {
      font-size: 14px;
      color: #516f90;
    }

    .edit {
      height: auto;
      color: #1a73e8;
      font-size: 14px;
      font-weight: 600;
    }

    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      .price {
        font-weight: bold;
        font-size: 15px;
        color: #101928;
        margin-right: 16px;
      }
      .boka {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 87px;
        height: 39px;
        text-decoration: none;
        border-radius: 6px;
        background: #1a73e8;
        color: white;
        box-sizing: border-box;
        font-weight: 500;
        font-size: 14px;
        margin-right: 18px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08);
      }
    }

    .choose {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      // border: 2px solid #253342;
      margin-right: 10px;
      cursor: pointer;
      transition: background 0.2s;

      svg {
        font-size: 20px;
        color: #101928;
      }
    }

    &.choose {
      cursor: pointer;
      .description {
        pointer-events: none;
      }
      .choose {
        pointer-events: none;
      }
    }

    &.booked {
      .choose {
        background: #1a73e8;
        border: 2px solid #1a73e8;
      }
    }
  }

  border-bottom: 1px solid #eaf0f6;
  // padding-bottom: 39px;
}

@media (max-width: 1024px) {
  .ServicesDisplay {
    .Service {
      margin: 0 20px;
      border-bottom: 1px solid #eaf0f6;

      &:last-of-type {
        border-bottom: none;
      }

      .description {
        margin-left: 0;
      }
    }
  }
}
