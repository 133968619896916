.CancelCode {
  width: 497px;
  height: 316px;
  border-radius: 8px;
  box-shadow: 0px 0px 12px 0px rgba(81, 111, 144, 0.12);
  margin: 0 auto;
  background: white;
  margin-top: 35px;
  padding: 0 35px;
  box-sizing: border-box;
  text-align: center;

  h3 {
    padding-top: 35px;
    padding-bottom: 12px;
    margin: 0;
    font-size: 22px;
  }

  p {
    margin: 0;
    margin-bottom: 32px;
    font-size: 15px;
  }

  button {
    width: 100%;
    height: 42px;
    background: #1a73e8;
    border-radius: 8px;
    color: white;
    font-size: 14px;
    font-weight: 600;
  }
}

@media (max-width: 1024px) {
  .CancelCode {
    width: 100%;
    height: 100%;
    padding: 0 20px;
    margin-top: 0;
    box-shadow: none;

    h3,
    button,
    .SignupInput {
      max-width: 334px;
      margin-left: auto;
      margin-right: auto;
    }

    p {
      margin-bottom: 40px;
    }
  }
}
