.Book {
  background: #f5f8fa;
  min-height: 100vh;
  .container {
    display: flex;
    justify-content: space-between;
    position: relative;
    top: -31px;
    width: 846px;
    margin: 0 auto;
    padding-bottom: 120px;

    .main {
      width: 518px;
      height: 100%;
      background: white;
      box-shadow: 0px 4px 12px 0px rgba(81, 111, 144, 0.12);
      border-radius: 8px;
      overflow: hidden;
      z-index: 0;

      .Personell {
        margin: 29px 34px;
      }

      .WholeLogin {
        padding: 43px 40px;
        box-sizing: border-box;
      }
    }

    .receipt {
      position: relative;
      width: 301px;
      height: 100%;
      box-shadow: 0px 4px 12px 0px rgba(81, 111, 144, 0.12);
      background: white;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      padding-bottom: 20px;

      &::after {
        position: absolute;
        content: "";
        width: 301px;
        height: 20px;
        background: white;
        bottom: 0;
        left: 0;
      }

      .Ticket {
        margin: 0;
      }

      .bottom {
        // position: relative;
        // z-index: 10;
        // position: absolute;
        // background: url("../../../assets/Book/recieptBottom.png");
        // height: 56px;
        // width: 325px;
        // left: -6px;
        // bottom: -26px;
        // z-index: 10;

        text-align: center;
        height: 20px;
        position: absolute;
        overflow: hidden;
        background: transparent;
        width: 100%;
        left: 0px;
        bottom: -15px;
        margin-top: -8px;

        &::before {
          content: "";
          position: absolute;
          left: 5px;
          bottom: 0px;
          right: 0px;
          background-repeat: repeat-x;
          height: 10px;
          background-size: 40px 23px;
          background-image: radial-gradient(
            circle at 8px -5px,
            rgb(255, 255, 255) 12px,
            rgba(255, 255, 255, 0) 13px
          );
        }

        &::after {
          content: "";
          position: absolute;
          left: -15px;
          bottom: 0px;
          right: 0px;
          background-repeat: repeat-x;
          height: 15px;
          background-size: 40px 8px;
          background-image: radial-gradient(
            circle at 8px 17px,
            rgba(255, 255, 255, 0) 13px,
            rgb(255, 255, 255) 14px
          );
        }
      }
    }
  }

  .continue {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: sticky;
    width: 100%;
    height: 86px;
    background: white;
    top: 100%;
    box-shadow: 0px -2px 8px 0px rgba(81, 111, 144, 0.12);

    button {
      width: 214px;
      height: 42px;
      background: #1a73e8;
      border-radius: 8px;
      margin-right: 31px;
      font-weight: 600;
      font-size: 14px;
      color: #fff;
    }
  }

  .confirm-book {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: fixed;
    width: 100%;
    height: 123px;
    background: white;
    bottom: 0;
    left: 0;
    box-shadow: 0px -2px 8px 0px rgba(81, 111, 144, 0.12);

    button {
      width: 320px;
      min-height: 42px;
      background: #1a73e8;
      border-radius: 4px;
      font-weight: 600;
      font-size: 14px;
      color: #fff;
      margin-top: 25px;
      margin-bottom: 13px;

      &:disabled {
        background: #ccc;
      }
    }

    p {
      font-size: 12px;
      color: #516f90;

      a {
        text-decoration: none;
        color: #1a73e8;
      }
    }
  }
}

@media (max-width: 1024px) {
  .Book {
    .BookNavigation {
      height: 100px;
      padding-top: 0;
      .container {
        padding: 23px 20px 0 20px;
        .title {
          .back {
            position: relative;
            left: 0;
          }
        }
      }
    }
    .container {
      top: 0;
      width: 100%;
      height: calc(100vh - 100px);
      padding-bottom: 0;
      .main {
        width: 100%;
        height: calc(100vh - 100px);
        .Personell {
          margin: 20px;
        }
        .ServicesDisplay {
          .Service {
            .choose {
              margin-right: 0;
              padding: 0;
            }
          }
        }
      }
      .receipt {
        display: none;
      }
    }
    .confirm-book {
      text-align: center;
      padding: 0 20px;
      height: 115px;
      box-sizing: border-box;
      button {
        width: 100%;
        margin-bottom: 0;
        margin-top: 12px;
      }
    }
  }
}
