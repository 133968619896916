.Stores {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 23px;
  grid-row-gap: 30px;
  padding-bottom: 80px;

  &.favourite {
    width: 100%;

    .store {
      // max-width: 380px;
      margin: 0 auto;
    }
  }

  span {
    color: #516f90;
    font-size: 12px;
  }

  .store {
    overflow: hidden;
    position: relative;
    // max-width: 300px;
    width: 100%;
    height: 125px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 12px 0px #516f9021;
    cursor: pointer;
    transition: transform 300ms ease 0s;
    z-index: 1;
    text-decoration: none;
    // padding:0 17px;
    box-sizing: border-box;

    &:hover {
      transform: scale(1.05);
      .SearchPicture {
        img {
          filter: brightness(0.7);
        }
      }
    }

    &.search {
      height: 240px;
      width: 100%;
      border: rgba(133, 133, 133, 0.4) 1px solid;

      &::after {
        content: "";
        flex: auto;
      }

      .pictures {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 140px;
        background: #eaf0f6;
        // margin-top: 20px;

        svg {
          font-size: 107px;
          color: #cbd6e2;
        }
      }
    }

    .containerStore {
      display: flex;
      width: 95%;
      align-items: center;
      padding: 8px 16px;
      height: 100px;
      box-sizing: border-box;
    }

    .closeWrapper {
      position: absolute;
      top: 10px;
      right: 5px;
      border: 5px solid #fff;
      border-radius: 50px;

      .close {
        background-color: #eaf0f6;
        border: 1px solid #cbd6e2;
        display: flex;
        justify-content: center;
        border-radius: 50px;
        padding: 2px;

        svg {
          font-size: 20px;
          color: #99acc2;
        }
      }
    }

    .image {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 93px;
      height: 93px;
      border-radius: 4px;
      background: #eaf0f6;
      margin-right: 14px;

      svg {
        color: #cbd6e2;
        width: 50px;
        height: 45px;
      }
    }

    .description {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      height: 100%;

      .name {
        h5 {
          font-size: 16px;
          margin: 0;
          color: #253342;
        }
      }

      .stars {
        display: flex;
        align-items: center;
        svg {
          color: #ea9c46;
          width: 20px;
          height: 20px;
          // margin-right: 6px;
        }

        p {
          font-size: 14px;
          font-weight: bold;
          margin: 0 6px;
        }

        div {
          display: flex;
          align-items: center;
          margin-left: 7px;
          margin-right: 9px;

          svg {
            color: #ea9c46;
          }
        }

        span {
          font-size: 13px;
          margin: 0;
        }
      }

      span {
        display: block;
        margin-bottom: 3px;
      }
    }
  }
}

@media (max-width: 1000px) {
  .Stores {
    grid-template-columns: 1fr 1fr !important;
  }
}

@media (max-width: 520px) {
  .Stores {
    grid-template-columns: 1fr !important;
    grid-row-gap: 16px !important;
  }
}

// @media (max-width: 1024px) {
//   .Stores {
//     justify-content: center;
//   }
// }
