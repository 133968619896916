.LandingPageSearch {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background: white;
  z-index: 100;

  .search {
    display: flex;
    align-items: center;
    height: 56px;
    border-bottom: 1px solid #cbd6e2;

    svg {
      font-size: 20px;
      margin: 0 20px;
    }

    button {
      opacity: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      width: 22px;
      height: 22px;
      background: #eaf0f6;
      border-radius: 50%;
      padding: 0;
      transition: 0.2s;

      svg {
        color: #99acc2;
        margin: 0;
        font-size: 15px;
        padding: 4px;
      }
    }

    input {
      border: none;
      width: 100%;
      font-size: 16px;

      &::placeholder {
        color: #7c98b6;
        font-size: 16px;
      }
    }

    input:focus:not(:placeholder-shown) + button {
      opacity: 1;
      pointer-events: all;
    }
  }
}
