.WholeSignup {
  text-align: center;
  padding: 37px 40px;

  .agree {
    width: 100%;
    height: 42px;
    border-radius: 8px;
    background: #1a73e8;
    color: white;
    margin: 24px 0;
  }
}
