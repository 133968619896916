.Team {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 45px;

  .worker {
    text-align: center;

    .image {
      width: 112px;
      height: 112px;
      background: #c4c4c4;
      margin: 0 auto;
      margin-bottom: 18px;
      border-radius: 50%;
    }

    h5 {
      font-size: 16px;
      margin: 0;
      margin-bottom: 6px;
    }

    a {
      font-size: 15px;
      color: #1a73e8;
      text-decoration: none;
    }
  }
}

@media (max-width: 1024px) {
  .Team {
    display: block;

    .worker {
      padding-bottom: 50px;
    }
  }
}
