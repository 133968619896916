.StickyHeader {
  height: 56px;
  box-shadow: 0px 4px 12px 0px rgba(81, 111, 144, 0.12);
  background: white;

  .title {
    display: flex;
    align-items: center;
    max-width: 1440px;
    padding: 0 80px;
    margin: 0 auto;
    box-sizing: border-box;

    svg {
      width: 20px;
      height: 20px;
      margin-top: 2px;
      margin-right: 19px;
      cursor: pointer;
    }

    p {
      font-size: 16px;
      font-weight: 700;
    }
  }
}
