.Search {
  .container {
    background: white;
    box-shadow: 0px 8px 12px 0px #516f901e;
    position: sticky;
    top: 60px;
    z-index: 99;
    overflow: visible;

    .search-bar {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
      height: 100px;
    }
  }
}

@media (max-width: 1024px) {
  .Search {
    .SearchResult {
      .title {
        .titleContainer {
          padding: 0 40px;
        }
      }
    }
    .container {
      padding: 0 40px;
    }
  }
}
