.LandingPage {
  h2 {
    font-size: 32px;
    margin: 40px 0 24px 0;
    color: #101928;
  }
  .container {
    max-width: 1440px;
    padding: 0 80px;
    margin: 0 auto;
    box-sizing: border-box;
  }
}

@media (max-width: 1024px) {
  .LandingPage {
    h2 {
      font-size: 24px;
      margin-top: 26px;
      margin-bottom: 16px;
    }
    .container {
      padding: 0 40px;
    }
  }
}

@media (max-width: 1200px) {
  .LandingPage {
    .container {
      padding: 0 32px;
    }
  }
}

@media (max-width: 768px) {
  .LandingPage {
    .container {
      padding: 0 20px;
    }
  }
}
