.Reviews {
  padding: 33px 0;

  .reportForm {
    margin-top: 30px;
    position: fixed;
    width: 375px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fafafa;
    border-radius: 8px;
    z-index: 20;
    padding: 12px 25px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);

    .reportHeading {
      color: #1b1d21;
      margin: 0 !important;
    }

    .boxes {
      .reportRow {
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 30px;
        margin-bottom: 16px;

        .circle {
          width: 20px;
          height: 20px;
          border: 1px solid #484848;
          border-radius: 50px;
          // margin-right: 8px;

          .smallCircle {
            width: 12px;
            height: 12px;
            background-color: #1a73e8;
            border-radius: 50px;
            margin: auto;
            margin-top: 4px;
          }

          &.selected {
            border: 1px solid #1a73e8;
          }
        }

        p {
          font-size: 14px;
          margin-left: 8px;
          // overflow: hidden;
          // text-overflow: ellipsis;
          // display: -webkit-box;
          // -webkit-line-clamp: 1;
          // -webkit-box-orient: vertical;
        }
      }
    }

    .buttons {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;

      button {
        width: 48%;
        height: 48px;
        font-size: 14px;
        border-radius: 8px;
        letter-spacing: 0.8px;

        &.cancelBtn {
          border: 1px solid #484848;
        }

        &.submitReport {
          background: #1a73e8;
          color: #fff;
        }
      }
    }
  }

  .show {
    display: block;
    background: #ffffff;
    border: 1px solid #dfe3eb;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    box-shadow: 0px 4px 8px 0px rgba(81, 111, 144, 0.25);
    font-weight: 600;
    font-size: 14px;
    color: #101928;
    padding: 12px 24px;
  }
}

@media (max-width: 1024px) {
  .Reviews {
    padding-left: 33px;
    padding-right: 33px;
  }
  .show {
    margin: 0 auto;
  }
}

@media (max-width: 500px) {
  .reportForm {
    margin-top: 40px;
    width: 85% !important;
    padding: 12px 15px !important;

    p {
      font-size: 13px !important;
    }

    .buttons {
      margin-top: 20px !important;

      button {
        height: 40px;
      }
    }
  }
}

@media (max-width: 400px) {
  .circle {
    &.first {
      width: 24px !important;
    }
  }
}
