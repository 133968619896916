.PlacePersonell {
  padding-bottom: 40px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  width: 90%;

  a {
    text-decoration: none;
  }

  .person {
    width: 85px;
    height: 135px;
    .img {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 82px;
      height: 82px;
      background: #eaf0f6;
      border-radius: 50%;
      margin-right: 20px;
      // margin: 0 auto;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }

      .rating {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: -10px;
        width: 62px;
        height: 32px;
        background: white;
        border-radius: 50px;
        border: 1px solid #cbd6e2;

        span {
          color: #ffc830;
          font-size: 18px;
        }

        p {
          margin-left: 3px;
          font-size: 15px;
          font-weight: bold;
        }
      }
    }

    .name {
      text-align: center;
      p,
      span {
        margin: 0;
      }

      p {
        font-size: 16px;
        font-weight: bold;
        color: #33475b;
        margin-top: 15px;
      }

      span {
        font-size: 12px;
        color: #516f90;
      }
    }

    svg {
      width: 21px;
      height: 26px;
      color: #99acc2;
    }
  }
}

@media (max-width: 1024px) {
  .PlacePersonell {
    display: flex;
    width: auto;
    overflow: scroll;
    padding-left: 18px;

    &::-webkit-scrollbar {
      display: none;
    }

    .person {
      margin-right: 20px;
      padding-right: 20px;
    }
  }
}
