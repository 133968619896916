.AlreadyHaveAccount {
  border-top: 1px solid #eaf0f6;
  padding-top: 22px;
  margin-top: 26px;

  a,
  .login {
    color: #1a73e8;
    text-decoration: none;
    font-size: 16px;
    padding: 0;
  }
}
