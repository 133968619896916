.Favourite {
  padding-top: 70px;
  .content {
    max-width: 1440px;
    margin: 44px auto;
    padding: 0 80px;
    box-sizing: border-box;
  }
  .StickyHeader {
    .title {
      padding: 0 80px;
    }
  }
}

@media (max-width: 1024px) {
  .Favourite {
    .content {
      padding: 0 40px;
    }
    .StickyHeader {
      .title {
        padding: 0 40px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .Favourite {
    .content {
      padding: 0 32px;
    }
    .StickyHeader {
      .title {
        padding: 0 32px;
      }
    }
  }
}

@media (max-width: 768px) {
  .Favourite {
    .content {
      padding: 0 20px;
    }
    .StickyHeader {
      .title {
        padding: 0 20px;
      }
    }
  }
}
