.Bookings {
  width: 100%;
  .no_bookings {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;

    h1 {
      font-size: 24px;
      color: #253342;
    }

    p {
      font-size: 16px;
      text-align: center;
      color: #33475b;
    }

    .backBtn {
      margin-top: 33px;
      text-decoration: none;
      width: 223px;
      height: 42px;
      background: #d8f3f4;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #1a73e8;
      font-weight: 600;
    }
  }
  .SingleBooking {
    padding-top: 0;
    text-decoration: none;
    display: flex;
    width: 100%;
    height: 146px;
    border-radius: 8px;
    box-shadow: 0px 0px 12px 0px rgba(81, 111, 144, 0.12);
    margin-bottom: 17px;
    color: #253342;
    background-color: #fff;

    padding-left: 37px;
    box-sizing: border-box;
    transition: 0.2s;

    .date {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-weight: bold;

      p {
        font-size: 28px;
        margin: 0;
      }

      span {
        font-size: 14px;
        font-weight: 600;

        &:last-of-type {
          text-transform: uppercase;
        }
      }
    }

    .description {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 53px;

      p {
        margin: 0;
        font-size: 14px;
      }

      span {
        font-size: 14px;
        color: #7c98b6;
        padding: 5px 0;
      }

      .event {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        padding-bottom: 5px;
        color: #1a73e8;

        &.CANCELED {
          color: #f64e60;
        }
      }

      .service {
        font-weight: bold;
        font-size: 16px;
      }
    }

    &:hover {
      transform: scale(1.05);
    }
  }
}
