.SearchResult {
  position: relative;
  padding-top: 8px;
  // min-height: calc(100vh - 165px - 44px);
  min-height: 100vh;
  background: #fff;
  width: 100%;
  display: block;
  margin: 0 auto;

  .mobileMap {
    display: none;
  }

  .editSearch {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 200;
    height: 100%;
    background: rgba(37, 51, 66, 0.4);

    .editSearchContent {
      background-color: #fff;
      border-radius: 0px 0px 8px 8px;
      padding-bottom: 20px;
      .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;
        h4 {
          text-align: center;
          width: 100%;
          font-weight: 600;
          font-size: 16px;
          color: #253342;
        }
      }

      .inputContainer {
        width: 90%;
        margin: 0 auto;

        input {
          margin: 0;
          border-color: #cbd6e2;
          border-radius: 12px 12px 0px 0px;
        }

        .secondInput {
          input {
            border-top: none;
            border-color: #cbd6e2;
            border-radius: 0px 0px 12px 12px;
          }
        }

        .searchIcon {
          position: absolute;
          left: 10px;
          top: 13px;
          fill: #1a73e8;
        }

        .pinIcon {
          position: absolute;
          left: 10px;
          top: 13px;
          fill: #fff;
        }

        button {
          background: #1a73e8;
          border: 1px solid #1a73e8;
          box-sizing: border-box;
          border-radius: 8px;
          width: 100%;
          color: #fff;
          height: 42px;
          font-size: 15px;
          margin-top: 16px;
        }
      }
    }
  }

  .searchMobile {
    display: none;
  }

  .title {
    padding-top: 20px;
    box-sizing: border-box;
    position: sticky;
    top: -20px;
    z-index: 99;
    margin: 0 auto;
    margin-top: 60px;
    background-color: #fff;
    box-sizing: border-box;
    width: 100%;
    transition: 0.3s;

    &.scroll {
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    }
    .titleContainer {
      display: flex;
      flex-direction: column;
      align-items: space-between;
      max-width: 1440px;
      margin: 0 auto;
      padding: 0 80px;
      box-sizing: border-box;
    }

    span {
      color: #516f90;
      font-size: 15px;
    }

    h3 {
      margin: 0;
      font-size: 30px;
      margin-top: 6px;
      color: #253342;
    }

    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 27px 0;

      .showMap {
        display: flex;
        align-items: center;

        span {
          font-size: 14px;
          font-weight: 600;
          margin-right: 19px;
        }
      }
    }
  }

  .container {
    max-width: 1440px;
    padding: 0 80px;
    box-sizing: border-box;
    height: 100%;
    margin: 0 auto;

    .titleMobile {
      display: none;
    }
  }

  .content {
    position: relative;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    margin-top: 60px;

    &.show-map {
      .Stores {
        grid-template-columns: 1fr 1fr;
        margin-right: 36px;
      }

      .map {
        display: block;
      }
    }

    .map {
      display: none;
      min-width: 680px;
      position: sticky;
      z-index: 0;
      height: calc(100vh - 295px);
      top: 160px;

      .mapDisplay {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media (max-width: 1200px) {
  .SearchResult {
    .content {
      .map {
        min-width: 64%;
      }
    }
  }
}

@media (max-width: 1024px) {
  .Navigation {
    .container {
      padding: 0 40px;
    }
  }
  .SearchResult {
    padding: 0;

    .mobileMap {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 200;

      .mapDisplay {
        width: 100%;
        height: 100%;

        .leaflet-control-zoom {
          margin-top: 80px !important;
          border-radius: 7px;
          overflow: hidden;

          // .leaflet-control-zoom-in {
          //   border
          // }
        }
      }

      .flex {
        display: flex;
        justify-content: space-between;
        position: absolute;
        padding: 0 13px;
        top: 10px;
        right: 0;
        left: 0;
        z-index: 2000;
        height: 45px;
        width: 92%;

        .left {
          display: flex;
          align-items: center;
          background-color: #fff;
          border-radius: 8px;
          padding: 0 10px;
          box-shadow: 0px 4px 12px rgba(81, 111, 144, 0.12);

          p {
            color: #516f90;
            font-size: 13px;
          }

          .checkbox {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 11px;
            width: 15px;
            height: 13px;
            border: 1px solid #cbd6e2;
            border-radius: 2px;

            svg {
              color: white;
              font-size: 13px;
            }

            &.active {
              background: #33475b;
              border-color: transparent;
            }
          }
        }

        .right {
          background: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 45px;
          border-radius: 8px;
          box-shadow: 0px 4px 12px rgba(81, 111, 144, 0.12);
        }
      }
    }

    .searchMobile {
      display: block;
      padding: 10px 12px 3px 12px;
      background: #ffffff;
      box-shadow: 0px 8px 12px rgba(81, 111, 144, 0.08);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 99;

      input {
        padding-left: 40px;
        background-color: #fff;

        // &::placeholder {
        //   color: #253342;
        //   opacity: 1;
        // }
      }

      .searchIcon {
        position: absolute;
        left: 12px;
        top: 13px;
        fill: #1a73e8 !important;
      }

      .searchMobileMap {
        position: absolute;
        right: 55px;
        top: -25px;

        svg {
          border-left: 1px solid #cbd6e2;
          padding-left: 10px;
          fill: #fff;
        }
      }
    }

    .map {
      display: none !important;
    }
    .title {
      display: none;
    }

    .container {
      padding: 0 40px;

      .content {
        padding: 0 !important;
        box-sizing: border-box;
        .Stores {
          grid-template-columns: repeat(2, 1fr);
        }
      }

      .titleMobile {
        display: block;
        // padding: 0 20px;
        padding-top: 80px;
        h3 {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #253342;
        }
      }
    }

    .content {
      display: block;
    }

    .results {
      width: 100%;
    }
  }
}

@media (max-width: 416px) {
  .SearchResult {
    .searchResultsContainer {
      .title {
        padding: 0;
        .flex {
          .showMap {
            span {
              margin-right: 8px;
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .SearchResult {
    .Stores {
      grid-template-columns: repeat(3, 1fr);
    }
    .title {
      .titleContainer {
        padding: 0 32px;
      }
    }
    .container {
      padding: 0 32px !important;
    }
  }
  .Navigation {
    .container {
      padding: 0 32px;
    }
  }
}

@media (max-width: 768px) {
  .SearchResult {
    .title {
      .titleContainer {
        padding: 0 20px;
      }
    }
    .container {
      padding: 0 20px !important;
    }
  }
  .Navigation {
    .container {
      padding: 0 20px;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1318px) {
  .SearchResult {
    .content {
      &.show-map {
        .Stores {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}
