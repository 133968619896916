.BookReview {
  height: 100%;
  width: 100%;
  margin: 0;
  .loggedIn {
    display: flex;
    align-items: center;
    height: 63px;
    background: #d2eafc;
    padding: 0 35px;
    box-sizing: border-box;

    p {
      margin: 0;
    }

    span {
      color: #1a73e8;
      font-weight: 600;
    }
  }

  .main {
    padding: 33px 35px 0 35px;
    box-sizing: border-box;
    .receipt.mobile {
      display: none;
    }
    .addBookingNotes {
      display: none;
    }

    label {
      font-size: 16px;
      font-weight: bold;
      color: #253342;
    }

    .cancelationPolicy {
      padding-bottom: 25px;
      border-bottom: 1px solid #efefef;
      margin-bottom: 25px;

      p {
        margin: 8px 0 0 0;
        font-size: 16px;
      }
      &.mobile {
        display: none;
      }
    }

    textarea {
      height: 116px;
    }

    .terms {
      padding-bottom: 31px;
      display: flex;
      align-items: center;
      .checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 11px;
        width: 20px;
        height: 20px;
        border: 1px solid #cbd6e2;
        border-radius: 4px;
        cursor: pointer;

        svg {
          color: white;
          font-size: 15px;
        }

        &.active {
          background: #1a73e8;
          border-color: transparent;
        }
      }
      p {
        font-size: 16px;
      }

      button {
        padding: 0;
        font-size: 16px;
        color: #1a73e8;
        border-bottom: 1px solid #1a73e8;
      }
    }
  }
}

@media (max-width: 1024px) {
  .BookReview {
    .loggedIn {
      padding: 0 20px;
    }
    .main {
      padding: 20px 20px 0 20px;
      height: calc(100vh - 100px - 63px - 115px) !important;
      box-sizing: border-box;
      overflow-y: scroll !important;
      .addBookingNotes {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 0 24px 0;
        border-top: 1px solid #eaf0f6;
        border-bottom: 1px solid #eaf0f6;
        margin-bottom: 16px;
        cursor: pointer;
        &.added {
          .left {
            .notesIcon {
              border: 1px solid #cbd6e2;
              background: #f5f8fa;
              svg {
                color: #253342;
                path {
                  stroke: #253342;
                }
              }
            }
            svg {
              color: #253342;
              width: 20px;
              height: 20px;
            }
            p {
              color: #253342;
              margin-right: 10px;
            }
          }
        }
        .left {
          display: flex;
          align-items: center;
          .notesIcon {
            width: 56px;
            height: 56px;
            background: #e0f2ff;
            border: 1px solid;
            box-sizing: border-box;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 18px;
            svg {
              width: 30px;
              height: 30px;
            }
          }
          p {
            font-weight: 500;
            font-size: 16px;
            // color: #1a73e8;
          }
        }
      }
      .desktopNotes {
        display: none;
      }
      .receipt.mobile {
        display: block;
        width: 100%;
        height: auto;
        box-shadow: none;
        .Ticket {
          box-shadow: none;
          max-width: 100%;
          padding: 0;
        }
      }
      .cancelationPolicy {
        &.desktop {
          display: none;
        }
        &.mobile {
          display: block;
          padding-top: 25px;
          border-top: 1px solid #efefef;
        }
      }
    }
  }
}
