.BookingNotes {
  // padding: 16px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 30;
  background-color: #fff;
  box-sizing: border-box;
  animation: slide-up2-fade-in ease 0.5s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  animation-fill-mode: forwards; /*when the spec is finished*/
  -webkit-animation: slide-up2-fade-in ease 0.5s;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-fill-mode: forwards; /*Chrome 16+, Safari 4+*/
  -moz-animation: slide-up2-fade-in ease 0.5s;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -moz-animation-fill-mode: forwards; /*FF 5+*/
  -o-animation: slide-up2-fade-in ease 0.5s;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -o-animation-fill-mode: forwards; /*Not implemented yet*/
  -ms-animation: slide-up2-fade-in ease 0.5s;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
  -ms-animation-fill-mode: forwards; /*IE 10+*/
  opacity: 0;
  opacity: 1\9;
  .notesContent {
    padding: 16px;
    box-sizing: border-box;
  }
  .header {
    background: #253342;
    display: flex;
    height: 64px;
    align-items: center;
    padding: 0 22px;
    .back {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      svg {
        color: #fff;
        font-size: 20px;
      }
    }
    h3 {
      font-weight: bold;
      font-size: 16px;
      color: #ffffff;
    }
  }
  p {
    margin: 0 0 16px 0;
    font-weight: normal;
    font-size: 14px;
    color: #253342;
  }
  textarea {
    width: 100%;
    height: 220px;
    border: 2px solid #cbd6e2;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 16px;
    resize: none;
    box-sizing: border-box;
    &:focus {
      border: 2px solid #0094ff;
    }
    &:not(:placeholder-shown) {
      border: 2px solid #0094ff;
    }
  }
  .termsBottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 71px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px -4px 12px -1px rgba(0, 0, 0, 0.08);
    button {
      width: 343px;
      height: 42px;
      background: #1a73e8;
      border-radius: 6px;
      font-weight: 600;
      font-size: 14px;
      color: #ffffff;
    }
  }
}

@keyframes slide-up2-fade-in {
  0% {
    opacity: 0;
    transform: translate(0px, 500px);
  }
  100% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
