.SignupInput {
  position: relative;

  .form-error {
    color: #f64e60;
  }

  &.svg {
    input {
      padding-left: 35px;
    }
  }

  label {
    color: #33475b;
    font-weight: 700;
    font-size: 14px;
    display: block;
    text-align: left !important;
  }
  p {
    font-size: 14px;
    margin: 4px 0;
  }
  input,
  textarea {
    display: block;
    width: 100%;
    height: 46px;
    border-radius: 4px;
    border: 1px solid #cbd6e2;
    margin-top: 6px;
    margin-bottom: 16px;
    padding: 0 16px;
    font-size: 15px;
    box-sizing: border-box;

    &:focus {
      border: 1px solid #1a73e8 !important;
    }

    &::placeholder {
      font-size: 16px;
      line-height: 22px;
      color: #99acc2;
    }

    &.error {
      margin-bottom: 0px;
    }
  }

  input:valid,
  textarea:not(:placeholder-shown) {
    border: 1px solid #1a73e8;
  }

  input:hover,
  textarea:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
  }

  input:disabled {
    background: #eaf0f6;
  }

  textarea {
    height: 123px;
    padding: 10px 10px;
    resize: none;
  }

  .createAndShow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    p {
      color: #1a73e8;
      font-weight: 600;
      font-size: 12px;
      margin: 0;

      &.active {
        text-decoration: underline;
      }
    }
  }

  input:focus + svg,
  input:valid + svg {
    fill: #1a73e8;
  }

  svg {
    position: absolute;
    width: 17.5px;
    height: 17.5px;
    left: 12px;
    top: 37px;
    fill: #cbd6e2;
  }

  button {
    margin: 0;
    padding: 0;
    color: #1a73e8;
    font-weight: 500;
    font-size: 12px;
  }
}
