.App {
  height: 100%;
}

p {
  color: #33475b;
}

button {
  background: none;
  border: none;
  cursor: pointer;
}

input,
textarea,
button,
select {
  &:focus {
    outline: transparent;
  }
}

.radiobox {
  width: 66px;
  background: #eaf0f6;
  border-radius: 500px;
  height: 34px;
  float: right;
  transition: 0.2s;
  cursor: pointer;

  .input {
    position: relative;
    left: 6px;
    width: 26px;
    height: 26px;
    background: white;
    border-radius: 50%;
    margin: 4px 0;
    transition: 0.2s;
  }

  &.active {
    background: #1a73e8;

    .input {
      left: 35px;
    }
  }
}
