.Navigation {
  height: 65px;
  margin: 0 auto;
  padding-top: 5px;
  position: fixed;
  z-index: 100;
  background: white;
  width: 100%;
  transition: 0.3s;
  border-bottom: 1px solid #dfe3eb;
  &.bottom-shadow {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  }

  &.main {
    background: transparent;
    border-bottom: transparent;
    .logo .logo-img {
      background: url("../../../assets/layout/Bookybook.png") no-repeat center
        center;
      background-size: cover;
      width: 163.2px;
      height: 28px;
    }
    .links {
      a,
      .display {
        color: #253342;

        &.button {
          background: transparent;
          font-size: 15px;
          color: #253342;
        }

        &.booking svg {
          fill: white;
        }
      }
    }

    .links a {
      &:hover {
        background: transparent;
      }

      &.button:hover {
        background: #f5f8fa;
      }
    }

    .links .mobile .hamburger {
      .line {
        background: white;
      }

      &.active .line {
        background: black;
      }
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 80px;
    box-sizing: border-box;

    .search {
      background: #ffffff;
      border: 1px solid #cbd6e2;
      box-sizing: border-box;
      border-radius: 8px;
      position: relative;
      display: flex;
      justify-content: center;
      height: 45px;
      border-radius: 8px;
      margin: 20px auto;

      .input:nth-of-type(2) {
        ::before {
          position: absolute;
          content: "";
          height: 25px;
          top: -2px;
          width: 1px;
          background: #cbd6e2;
          left: -15px;
          transition: 0.5s;
        }
      }

      &.active {
        background-color: #f5f8fa;

        .input:nth-of-type(2) {
          ::before {
            width: 0px;
          }
        }
      }

      // &:focus-within {
      //   background-color: #f5f8fa;
      // }

      .input {
        position: relative;

        .inputIcon {
          position: absolute;
          left: 15px;
          top: 12px;

          svg {
            path {
              stroke: #1a73e8;
            }
          }
        }

        .close {
          width: 22px;
          height: 22px;
          background: #eaf0f6;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 235px;
          cursor: pointer;
          z-index: 1000;
          svg {
            position: relative;
            left: 5px;
            width: 12px;
            height: 12px;
            color: #253342;
            cursor: pointer;
          }
        }

        &:nth-of-type(2) {
          input {
            width: 240px;
            padding-right: 48px;
          }
          .close {
            left: 200px;
          }
        }
      }

      input {
        position: relative;
        width: 220px;
        height: 100%;
        padding-left: 40px;
        font-size: 16px;
        border: none;
        box-sizing: border-box;
        border-radius: 8px;
        background: transparent;
        cursor: pointer;
        transition: all 0.3s;

        // &:nth-of-type(1) {
        // &::after {
        //   position: absolute;
        //   content: "";
        //   height: 25px;
        //   top: -2px;
        //   width: 1px;
        //   background: #cbd6e2;
        //   left: 195px;
        //   transition: 0.5s;
        //   z-index: 200000;
        // }
        // }

        &::placeholder {
          color: #99acc2;
          font-size: 15px;
        }

        &.focused {
          background: #fff;
          box-shadow: 0.5px 0px 12px 0px rgba(81, 111, 144, 0.12);
          width: 270px !important;
        }

        // &:focus {
        //   background: #fff;
        //   box-shadow: 0.5px 0px 12px 0px rgba(81, 111, 144, 0.12);
        //   width: 270px !important;
        // }

        // &:not(:placeholder-shown) {
        //   min-width: 354px;
        // }
      }

      .searchButton {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 8px;
        right: 6px;
        width: 37px;
        height: 37px;
        font-size: 15px;
        background: #1a73e8;
        color: white;
        border: none;
        z-index: 5;
        cursor: pointer;
        overflow: hidden;
        transition: 0.3s;

        span {
          opacity: 0;
          width: 0;
          transition: 0.2s;
          font-size: 16px;
        }

        svg {
          background: #1a73e8;
          font-size: 16px;
          path {
            stroke: white;
          }
        }
      }
    }

    .desktop {
      display: flex;
      align-items: center;
    }
  }

  .hamburger {
    position: relative;
    width: 21px;
    height: 16px;
    margin-top: 12px;
    margin-left: 23px;
    bottom: 6px;
    cursor: pointer;

    .line {
      position: absolute;
      height: 2px;
      width: 100%;
      background: black;
      border-radius: 50px;
      transition: all 0.2s, opacity 0.1s;

      &:nth-of-type(1) {
        top: 0%;
      }

      &:nth-of-type(2) {
        top: 50%;
        transform: translateY(-50%);
      }

      &:nth-of-type(3) {
        top: 100%;
        transform: translateY(-100%);
      }
    }

    &.active {
      .line {
        &:nth-of-type(1) {
          top: 45%;
          transform: rotate(45deg);
        }

        &:nth-of-type(2) {
          transform: translateX(100%);
          opacity: 0;
        }

        &:nth-of-type(3) {
          top: 45%;
          transform: rotate(-45deg);
        }
      }
    }
  }

  .logo {
    .logo-img {
      background: url("../../../assets/layout/Bookybook.png") no-repeat center
        center;
      background-size: cover;
      width: 163.2px;
      height: 28px;
    }
  }

  .links {
    a,
    .display {
      align-items: center;
      text-decoration: none;
      color: #101928;
      font-size: 15px;
      transition: 0.3s;

      padding: 5px 10px;
      margin-left: 20px;
      border-radius: 12px;

      &:hover {
        background: #f5f8fa;
      }

      &.button {
        background: #101928;
        color: #fff;
        border-radius: 6px;
        padding: 8px 16px;
        max-height: 36px;
        max-width: 172px;
        font-size: 14px;
      }

      svg {
        position: relative;
        top: 2px;
        margin-right: 6px;
        stroke: #33475b;
      }

      &.booking {
        svg {
          fill: #1a73e8;
        }
      }
    }

    .mobile {
      display: none;
    }
  }

  .mobile-nav {
    display: none;
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100%;
    background: white;
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s;

    &.modal-open {
      opacity: 1;
      pointer-events: all;
      overflow-y: hidden;
    }

    .container {
      flex-direction: column;
      margin: 0 auto;
      margin-top: 60px;
      min-width: 100%;
      box-sizing: border-box;
      min-height: 100%;
      padding: 0 !important;
      justify-content: flex-start;

      .forB2b {
        width: 100%;
        background: #f5f8fa;
        p {
          font-size: 16px;
          color: #516f90;
          padding: 0 20px;
          margin: 14px 0;
        }
      }

      .mobileLink {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        text-decoration: none;
        height: 60px;

        p {
          padding-left: 22px;
        }

        .linkWithIcon {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: 20px;

          p {
            padding: 0;
          }

          svg {
            padding: 0;
            padding-right: 20px;
            width: 20px;
            height: 20px;
            color: #253342;
          }
        }

        svg {
          padding: 0 20px;
          color: #253342;
        }

        a,
        .logout {
          font-size: 17px;
          color: #253342;
          text-decoration: none;
          font-weight: 500;
          width: 100%;
          cursor: pointer;
          padding: 0 20px;
        }
      }
    }

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 271px;
      margin: 0 auto;
      min-height: 52px;
      // background: #253342;
      color: #253342;
      border-radius: 8px;
      text-decoration: none;
      font-size: 18px;
      margin-bottom: 20px;
      font-weight: 500;

      &.green {
        background: #1a73e8;
      }
    }
  }
}

@media (max-width: 1024px) {
  .Navigation {
    width: 100%;
    top: 0;
    &.place {
      display: none;
    }

    &.searchResults {
      display: none;
    }

    .container {
      padding: 0 12px 0 0 !important;

      .search {
        display: none;
      }
      .logo {
        margin-left: 20px;
      }
    }
    &.main .logo .logo-img {
      height: 20px;
      width: 118px;
    }
    .logo {
      z-index: 150;
      // margin-left: 17px;

      .logo-img {
        height: 20px;
        width: 118px;
      }
    }

    .links {
      z-index: 150;
      margin-right: 16px;

      .desktop {
        display: none;
      }

      .mobile {
        display: flex;
        align-items: center;
      }
    }

    .mobile-nav {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
