.ReviewAverage {
  display: flex;
  justify-content: space-between;
  height: 110px;

  .mark {
    width: 155px;
    // margin-right: 40px;
    p {
      font-size: 40px;
      font-weight: bold;
      margin: 0;
      color: #101928;
      span {
        font-size: 26px;
      }
    }

    .numberOfReviews {
      font-size: 14px;
      color: #516f90;
    }
  }

  .statistics {
    text-align: right;
    margin-right: 40px;

    .flex {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      p {
        font-size: 13px;
        margin: 0;
        color: #7c98b6;
        height: 15px;
      }
    }
  }
  .verifiedBy {
    display: block;
  }
}
.verifiedBy {
  display: none;
  padding: 16px;
  height: fit-content;
  box-sizing: border-box;
  background: #f5f8fa;
  border-radius: 4px;
  .verifiedHeading {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    color: #101928;
    svg {
      color: #1da532;
      margin-right: 8px;
    }
  }
  p {
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: #101928;
    margin-bottom: 0;
  }
}

@media (max-width: 1440px) {
  .ReviewAverage {
    .statistics {
      margin-right: 10px;
    }
  }
}

@media (max-width: 1024px) {
  .ReviewAverage {
    margin: 0 18px;

    .mark {
      margin-right: 0;
    }
    .recensions {
      margin: 5px 0;
    }
    .statistics {
      margin-right: 0;
    }
    .verifiedBy {
      display: none !important;
    }
  }
  .verifiedBy {
    display: block;
  }
}
