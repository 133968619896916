.PlaceNameMobile {
  margin-top: 24px;
  display: none;
  padding: 0 20px;
  h1 {
    margin: 0;
    font-size: 20px;
  }

  span {
    font-weight: normal;
    font-size: 15px;
    color: #101928;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .OpeningHours {
    margin-top: 6px;
  }

  .rating {
    display: flex;
    align-items: center;

    p {
      font-size: 16px;
      font-weight: bold;
      margin: 0;
      margin-right: 7px;
      color: #101928;
    }

    .Stars {
      display: flex;
      align-items: flex-start;
      margin-right: 5px;
      height: 35px;

      svg {
        fill: #ea9c46 !important;
        margin-top: 3px;
        width: 15px;
        height: 15px;
      }
    }
  }

  .placeInfo {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 0 0;
    padding-bottom: 25px;
    border-bottom: 1px solid #eaf0f6;

    .infoBox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #fff;
      border-radius: 12px;
      cursor: pointer;
      text-decoration: none;

      .iconWrapper {
        border: 1.19643px solid #1a73e8;
        border-radius: 50px;
        width: 50px;
        height: 50px;
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          color: #1a73e8;
          width: 24px;
          height: 24px;
        }
      }
      p {
        margin: 0;
        color: #1a73e8;
        margin-top: 4px;
        font-size: 15px;
      }
    }
  }
  .description {
    padding: 20px 0;
    border-bottom: 1px solid #dfe3eb;
    margin-bottom: 15px;
    p {
      font-size: 14px;
      line-height: 26px;
      color: #101928;
      margin: 6px 0;
    }
    button {
      padding: 0;
      font-size: 14px;
      color: #1a73e8;
    }
  }
}

@media (max-width: 368px) {
  .placeInfo {
    justify-content: space-between !important;
    .infoBox {
      width: 110px !important;
      height: 80px !important;
    }
  }
}
