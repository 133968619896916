.CancelBooking {
  padding-top: 70px;
  background: #f5f8fa;
  height: calc(100vh - 60px);
}

@media (max-width: 1024px) {
  .CancelBooking {
    height: 100%;
    .StickyHeader {
      display: none;
    }
  }
}
