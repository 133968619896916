.mapDisplay {
  .leaflet-popup-content-wrapper {
    border-radius: 0;
    cursor: pointer;
    .leaflet-popup-content {
      margin: 0;
      width: 200px !important;
      box-sizing: border-box;
      a {
        text-decoration: none;
        img {
          width: 100%;
          height: 100px;
        }
        .popupContent {
          padding: 4px 8px;
          h1 {
            font-size: 16px;
            margin: 0;
            color: #253342;
          }
          p {
            font-size: 14px;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1; /* number of lines to show */
            -webkit-box-orient: vertical;
            color: #253342;
          }
          .rate {
            display: flex;
            align-items: center;
            svg {
              color: #ea9c46;
              width: 18px;
              height: 18px;
              margin-right: 4px;
            }
            span {
              color: #253342;
            }
          }
        }
      }
    }
  }
}
